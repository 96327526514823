// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { Grid, Typography, Divider } from '@mui/material';

export default function DropImg(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const { monster } = props;

  /**
   *  Custom functions
   */
  const getImgpath = (fileName) => {
    const bathUrl = process.env.REACT_APP_GS_PUBLIC_URL;
    const dir = process.env.REACT_APP_GS_BUCKET_MONSTER_IMAGE;
    return `${bathUrl}/${dir}/${fileName}`;
  };

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.rareDropForParts")}</Typography>
      </Grid>

      {
        monster?.map((m) =>
          <Grid item={true} xs={12} key={m._id}>
            <Grid className="monster-star" item={true} xs={12}>
              <Divider textAlign="center">
                {
                  [...Array(m.star - 5)].map((e, i) =>
                    <img key={i} src="/images/purple-star.webp" alt="purple-star" />
                  )
                }
              </Divider>
            </Grid>
            <Grid className="breakable-drop" item={true} xs={12}>
              <img src={getImgpath(m.imageFile)} alt={m._id} />
            </Grid>
          </Grid>
        )
      }
    </>
  );
}