// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Modal, Fade, Paper, Backdrop
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// import contexts

// import custom components
import MotionModal from '../lists/Motion';

// import custom CSS
import '../../css/tool.css';

export default function DmgTypeInput(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [openMotionList, setOpenMotionList] = React.useState(false);
  const attack = React.useRef(0);
  const eattack = React.useRef(0);
  const motionVal = React.useRef(0);
  const hitZone = React.useRef(0);
  const modifier = React.useRef(1);
  const criticalModifier = React.useRef(1.25);
  const damage = React.useRef(0);
  const criticalDamage = React.useRef(0);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */
  const calculate = () => {
    const _damage = (Number(attack.current.value) + Number(eattack.current.value))
      * Number(motionVal.current.value) * Number(hitZone.current.value) * Number(modifier.current.value)
      / 10000;

    damage.current.value = Math.ceil(_damage);
    criticalDamage.current.value = Math.ceil(_damage * Number(criticalModifier.current.value));
  };


  /**
   *  Render the component
   */
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>

        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <TextField type="number" label={t("labels.pattackValue")} onChange={calculate} inputRef={attack}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
            <TextField type="number" label={t("labels.eattackValue")} onChange={calculate} inputRef={eattack}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
            <Stack direction="column" justifyContent="center" alignItems="center">
              <TextField type="number" label={t("labels.move") + t("labels.motionValue")} onChange={calculate} inputRef={motionVal}
                InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
              <Button variant="text" size="small" startIcon={<SearchIcon />} onClick={() => setOpenMotionList(true)}>
                {t("labels.openMotionList")}
              </Button>
            </Stack>
            <TextField type="number" label={t("labels.hitZone")} onChange={calculate} inputRef={hitZone}
              InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
          </Stack>

          <Modal open={openMotionList} onClose={() => setOpenMotionList(false)}
            closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
            <Fade in={openMotionList}>
              <Paper className="info-modal" sx={{ boxShadow: 24 }}>
                <MotionModal setIsLoading={props.setIsLoading} />
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Button variant="text" size="small" startIcon={<CloseIcon />} onClick={() => setOpenMotionList(false)}>
                    {t("labels.closeModal")}
                  </Button>
                </Stack>
              </Paper>
            </Fade>
          </Modal>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <TextField type="number" label={t("labels.damageModifier")} onChange={calculate} defaultValue={1.00.toFixed(1)} inputRef={modifier}
              InputLabelProps={{ shrink: true }} InputProps={{ inputMode: "numeric" }} />
            <TextField type="number" label={t("labels.criticalModifier")} onChange={calculate} defaultValue={1.25} inputRef={criticalModifier}
              InputLabelProps={{ shrink: true }} InputProps={{ inputMode: "numeric" }} />
          </Stack>

          <Typography variant="h5" textAlign="center">||</Typography>

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <TextField type="number" label={t("labels.damageValue")} inputRef={damage}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
            <TextField type="number" label={t("labels.criticalDamageValue")} inputRef={criticalDamage}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
          </Stack>
        </Stack>
      </Grid>

    </Grid >
  );
}