// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Paper,
  Select, MenuItem,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
} from '@mui/material';

// import custom components

// import custom CSS

export default function MonsterStatus(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const { monsters, statusType } = props;
  const [star, setStar] = React.useState(8);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
    })();
  }, []);

  /**
   *  Custom functions
   */
  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const getStatusObj = (specs) => {
    const target = specs.find(s => s.star === star);

    return target && target[statusType]?.resistance > 0 ? target[statusType] : null;
  };

  /**
   *  Custome variables
   */
  const nf = new Intl.NumberFormat('en-US');

  /**
   *  Render the component
   */
  return (
    <>
      <Grid item={true} xs={12}>
        <Select className="monster-status-star" size="small" value={star} onChange={(event) => setStar(event.target.value)}>
          {
            [8, 9, 10].map(i =>
              <MenuItem value={i} key={i}>
                {[...Array(i - 5)].map((e, j) =>
                  <img className="purple-star" key={j} src="/images/purple-star.webp" alt="purple-star" />
                )}
              </MenuItem>
            )
          }
        </Select>
      </Grid>
      <TableContainer className="monster-status-table" component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <StyledTableHeadRow>
              <TableCell align="left" sx={{ width: 140 }}>{t("labels.monsterName")}</TableCell>
              <TableCell align="center">{t("labels.statusCondition.threshold")}</TableCell>
              {
                statusType === "poison" &&
                <TableCell align="center">{t("labels.statusCondition.poisonDamage")}</TableCell>
              }
              {
                statusType === "blast" &&
                <TableCell align="center">{t("labels.statusCondition.explodeDamage")}</TableCell>
              }
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {
              monsters.map(m => {
                const statusVal = getStatusObj(m.specs);
                return (
                  statusVal &&
                  <TableRow key={m._id}>
                    <TableCell align="left">
                      <Stack direction="row" justifyContent="flex-start" alignItems="center">
                        <img className="monster-status-icon" src={`/images/${m.imageFile}`} alt={m.name} />
                        {t((`monsters.${m.name}.name`))}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      {nf.format(statusVal.resistance)}
                    </TableCell>
                    {
                      (statusType === "poison" || statusType === "blast") &&
                      <TableCell align="center">
                        {nf.format(statusVal.damage)}
                      </TableCell>
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}