// import React libraries
import React from 'react';

// import third-party libraries
import {
  Grid
} from '@mui/material';

// import contexts

// import custom components
import HitPoint from './lists/HitPoint';
import RareDrop from './lists/RareDrop';

// import custom CSS
import '../css/top.css';

export default function Top(props) {
  /**
   *  React variables
   */

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */
  React.useEffect(() => {
  }, []);

  /**
   *  Custom functions
   */

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <HitPoint setIsLoading={props.setIsLoading} />
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <RareDrop setIsLoading={props.setIsLoading} />
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>
    </Grid>
  );
}