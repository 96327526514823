// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Typography,
  Stack,
  Fab,
  SvgIcon,
  Select, MenuItem,
  Divider,
  Autocomplete,
  TextField,
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import DmgMotion from './DmgMotion';

// import custom CSS
import '../../css/tool.css';

// import custom svgs
import { ReactComponent as SnsIcon } from '../../svg/weapons/sns.svg'
import { ReactComponent as GsIcon } from '../../svg/weapons/gs.svg'
import { ReactComponent as LsIcon } from '../../svg/weapons/ls.svg'
import { ReactComponent as HammerIcon } from '../../svg/weapons/hammer.svg'
import { ReactComponent as LbIcon } from '../../svg/weapons/lb.svg'
import { ReactComponent as BowIcon } from '../../svg/weapons/bow.svg'
import { ReactComponent as LanceIcon } from '../../svg/weapons/lance.svg'
import { ReactComponent as DbIcon } from '../../svg/weapons/db.svg'
import { ReactComponent as CbIcon } from '../../svg/weapons/cb.svg'
import { ReactComponent as GlIcon } from '../../svg/weapons/gl.svg'

export default function DmgTypeSelect(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [monsters, setMonsters] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [weapons, setWeapons] = React.useState([]);
  const [driftsmetingParams, setDriftsmetingParams] = React.useState({ attack: 0, affinity: 0 });

  const [currentMonser, setCurrentMonster] = React.useState("");
  const [currentWeapon, setCurrentWeapon] = React.useState("");
  const [currentWeaponStat, setCurrentWeaponStat] = React.useState("");
  const [currentSkills, setCurrentSkills] = React.useState([]);
  const [currentMiscConditions, setCurrentMiscConditions] = React.useState([]);

  const defaultStats = {
    attack: 0, eattack: 0, affinity: 0, weaknessAffinity: 0,
    modifier: 1.00, criticalModifier: [1.25, 1.25], spModifier: 1.00, aerialModifier: 1.00, blastModifier: 1.00
  };
  const [stats, setStats] = React.useState(defaultStats);

  const [miscConditionList] = React.useState([
    { label: "paralysis", type: "all", modifier: 1.1 },
    { label: "sleep", type: "all", modifier: 2.0 },
    { label: "mud", type: "monster", modifier: 0.3, monsters: ["barroth", "jyuratodus"] },
    { label: "spiritGauge", type: "weapon", modifier: 1.5, weapons: ["ls"] },
    { label: "outOfRange", type: "weapon", modifier: 0.4, weapons: ["lb", "bow"] },
    { label: "shieldCharge", type: "weapon", modifier: 1.2, weapons: ["cb"] }
  ]);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonsters();
      await getSkills();
      await getWeapons();
      props.setIsLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    updateStats();
  }, [currentMonser, currentWeaponStat, currentSkills, currentMiscConditions, driftsmetingParams]);

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */
  const getMonsters = async () => {
    const res = await axios.get(`/monsters?details=true`);
    setMonsters(res.data);
  };

  const getSkills = async () => {
    const res = await axios.get(`/skills?forCalculation=true`);
    const expandedSkillList = [];
    res.data.forEach(s =>
      s.levels.forEach(l => {
        const expandedSkill = {
          ...l,
          type: s.type,
          name: s.name
        };
        expandedSkillList.push(expandedSkill);
      })
    );
    setSkills(expandedSkillList);
  };

  const getWeapons = async () => {
    const res = await axios.get(`/weapons`);
    setWeapons(res.data);
  };

  const getMiscConditionList = () => {
    const list = [];

    miscConditionList.forEach(m => {
      switch (m.type) {
        case "monster":
          if (m.monsters.includes(currentMonser.name)) {
            list.push(m);
          }
          break;

        case "weapon":
          if (m.weapons.includes(currentWeapon.type)) {
            list.push(m);
          }
          break;

        default:
          list.push(m);
          break;
      }
    });

    return list;
  };

  const selectWeaponType = (weaponType) => {
    setCurrentWeapon({ type: weaponType });
    setCurrentWeaponStat("");
    setCurrentSkills([]);
    setCurrentMiscConditions([]);
  };

  const selectWeapon = (e) => {
    setCurrentWeapon(e.target.value);
    setCurrentWeaponStat("");
    setCurrentSkills([]);
    setCurrentMiscConditions([]);
  };

  const selectWeaponGrade = (e) => {
    setCurrentWeaponStat(e.target.value);
  };

  const selectSkills = (event, values) => {
    setCurrentSkills(values);
  };

  const selectMiscCondition = (event, values) => {
    setCurrentMiscConditions(values);
  };

  const updateStats = () => {
    if (currentWeaponStat === "") return;

    const affinityParam = driftsmetingParams.affinity / 100;
    const newStats = {
      attack: currentWeaponStat.attack + driftsmetingParams.attack,
      eattack: defaultStats.eattack,
      affinity: currentWeaponStat.affinity + affinityParam,
      weaknessAffinity: currentWeaponStat.affinity + affinityParam,
      modifier: defaultStats.modifier,
      criticalModifier: defaultStats.criticalModifier,
      spModifier: defaultStats.spModifier,
      aerialModifier: defaultStats.aerialModifier,
      blastModifier: defaultStats.blastModifier
    };

    if (typeof currentWeapon === "object"
      && currentMonser.weaks.includes(currentWeapon.element[0])
      && currentWeapon.element[0] !== "poison"
      && currentWeapon.element[0] !== "paralysis"
      && currentWeapon.element[0] !== "sleep") {
      newStats.eattack = currentWeaponStat.eattack;
    }

    const attackMultiplierSkills = currentSkills.filter(s => s.type === "attack_percent");
    let attackMultiplier = 0;
    attackMultiplierSkills.forEach(s => attackMultiplier += s.attack);
    newStats.attack = Math.floor(newStats.attack + newStats.attack * attackMultiplier);

    const attackSkills = currentSkills.filter(s => s.type === "attack");
    attackSkills.forEach(s => newStats.attack += s.attack);

    if (newStats.eattack) {
      const eAttackSkills = currentSkills.filter(s => s.type === "eattack");
      eAttackSkills.forEach(s => newStats.eattack += s.eattack);

      const eAttackMultiplierSkills = currentSkills.filter(s => s.type === "eattack_percent");
      eAttackMultiplierSkills.forEach(s => newStats.eattack *= s.eattack);
      newStats.eattack = Math.floor(newStats.eattack);
    }

    const affinitySkills = currentSkills.filter(s => s.type === "affinity");
    affinitySkills.forEach(s => {
      if (s.condition === "weakness") {
        newStats.weaknessAffinity += s.affinity;
      } else {
        newStats.affinity += s.affinity;
        newStats.weaknessAffinity += s.affinity;
      }
    });

    const modifierSkills = currentSkills.filter(s => s.type === "modifier");
    modifierSkills.forEach(s => {
      switch (s.condition) {
        case "critical":
          newStats.criticalModifier = [s.modifier, s.modifier];
          break;
        case "sp":
          newStats.spModifier += s.modifier;
          break;
        case "aerial":
          newStats.aerialModifier += s.modifier;
          break;
        case "blast":
          newStats.blastModifier += s.modifier;
          newStats.spModifier += s.modifier;
          break;
        default:
          newStats.modifier += s.modifier;
          newStats.spModifier += s.modifier;
          newStats.aerialModifier += s.modifier;
          newStats.blastModifier += s.modifier;
          break;
      }
    });

    newStats.attack *= currentMonser.attackModifier;
    newStats.eattack *= currentMonser.eattackModifier;
    if (currentWeaponStat.sp && currentWeapon.type !== "gl") {
      newStats.spModifier *= currentWeaponStat.sp.modifier;
    }

    if (newStats.affinity < 0) {
      newStats.criticalModifier[0] = 0.75;
    } else if (newStats.affinity === 0) {
      newStats.criticalModifier[0] = 1;
    }
    if (newStats.weaknessAffinity < 0) {
      newStats.criticalModifier[1] = 0.75;
    } else if (newStats.weaknessAffinity === 0) {
      newStats.criticalModifier[1] = 1;
    }

    setStats(newStats);
  };

  const setGradeColor = (grade) => {
    let textColor = "";
    switch (grade) {
      case 1:
        textColor = weaponGradeColor.grade1;
        break;
      case 2:
        textColor = weaponGradeColor.grade2;
        break;
      case 3:
        textColor = weaponGradeColor.grade3;
        break;
      case 4:
        textColor = weaponGradeColor.grade4;
        break;
      case 5:
        textColor = weaponGradeColor.grade5;
        break;

      default:
        textColor = weaponGradeColor.grade6;
        break;
    };

    return textColor;
  };

  /**
   *  Custome variables
   */
  const weaponList = [
    { name: "sns", icon: SnsIcon },
    { name: "db", icon: DbIcon },
    { name: "gs", icon: GsIcon },
    { name: "ls", icon: LsIcon },
    { name: "hammer", icon: HammerIcon },
    { name: "lance", icon: LanceIcon },
    { name: "gl", icon: GlIcon },
    { name: "cb", icon: CbIcon },
    { name: "lb", icon: LbIcon },
    { name: "bow", icon: BowIcon }
  ];

  const weaponGradeColor = {
    grade1: "#96aaaa",
    grade2: "#26a53c",
    grade3: "#056eb0",
    grade4: "#af50cc",
    grade5: "#e2ae22",
    grade6: "#e55c5b"
  };

  /**
   *  Render the component
   */
  return (
    <>
      {/** 討伐モンスター選択 */}
      <Grid className="section-divider" item={true} xs={12}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.targetMonster")}
          </Typography>
        </Divider>
      </Grid>
      <Grid className="target-monster-select" item={true} xs={12}>
        <Select className="target-monster-list" value={currentMonser} onChange={e => setCurrentMonster(e.target.value)}>
          {
            monsters?.map(m =>
              <MenuItem className="target-monster" value={m} key={m._id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img className="monster-icon" src={`/images/${m.imageFile}`} alt={m.name} />
                  <Typography variant="body2">
                    {t(`monsters.${m.name}.name`)}
                  </Typography>
                </Stack>
              </MenuItem>
            )
          }
        </Select>
      </Grid>

      {/** 武器種別選択 */}
      <Grid className="section-divider" item={true} xs={12}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.weaponType")}
          </Typography>
        </Divider>
      </Grid>
      <Stack className="weapon-type-select" direction="row" spacing={2} justifyContent="center" alignItems="center">
        {
          currentMonser ?
            weaponList.map(w =>
              <Fab size="small" color={currentWeapon.type === w.name ? "primary" : "secondary"} onClick={() => selectWeaponType(w.name)} key={w.name}>
                <SvgIcon component={w.icon} inheritViewBox />
              </Fab>
            )
            :
            <></>
        }
      </Stack>

      <Grid item={true} xs={12}>&nbsp;</Grid>

      {/** 武器G選択 */}
      <Grid className="section-divider" item={true} xs={12}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.weaponGrade")}
          </Typography>
        </Divider>
      </Grid>

      <Grid className="weapon-grade-select" container={true}>
        <Grid item={true} xs={8}>
          {
            currentWeapon.type ?
              <Select className="weapon-grade-list" value={currentWeapon.name ? currentWeapon : ""} onChange={selectWeapon}>
                {
                  weapons.filter(w => w.type === currentWeapon.type).map(w =>
                    <MenuItem className="weapon-name" value={w} key={w._id}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <img className="monster-icon" src={`/images/${w.monster}.webp`} alt={w.monster} key={w._id} />
                        <Typography variant="body2">
                          {t(`weapons.${w.name}`)}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )
                }
              </Select>
              :
              <></>
          }
        </Grid>
        <Grid item={true} xs={4} style={{ textAlign: "right" }}>
          {
            currentWeapon.name ?
              <Select className="weapon-grade-list" value={currentWeaponStat} onChange={selectWeaponGrade} style={{ textAlign: "left" }}>
                {
                  currentWeapon.grades.toReversed().map(g =>
                    <MenuItem className="weapon-grade" value={g} key={g._id} style={{ color: setGradeColor(g.grade) }}>
                      <Typography variant="body2">
                        {`G${g.grade}-${g.subgrade}`}
                      </Typography>
                    </MenuItem>
                  )
                }
              </Select>
              :
              <></>
          }
        </Grid>
      </Grid>

      {/** 錬成パラメータ設定 */}
      <Grid className="section-divider" item={true} xs={12}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.driftsmeltingParam")}
          </Typography>
        </Divider>
      </Grid>
      <Grid className="param-input" item={true} xs={12}>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <TextField fullWidth type="number" label={t("labels.attackValue")} onChange={(e) => setDriftsmetingParams({ ...driftsmetingParams, attack: Number(e.currentTarget.value) })}
            value={driftsmetingParams.attack} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
          <TextField fullWidth type="number" label={t("labels.affinity")} onChange={(e) => setDriftsmetingParams({ ...driftsmetingParams, affinity: Number(e.currentTarget.value) })}
            value={driftsmetingParams.affinity} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
        </Stack>
      </Grid>

      {/** 発動スキル選択 */}
      <Grid className="section-divider" item={true} xs={12}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.effectiveSkill")}
          </Typography>
        </Divider>
      </Grid>
      <Grid className="skill-select" item={true} xs={12}>
        {
          currentWeaponStat ?
            <Autocomplete
              multiple
              options={skills}
              getOptionLabel={(option) => `${t(`skills.${option.name}`)} Lv.${option.level}`}
              filterSelectedOptions
              onChange={selectSkills}
              renderInput={(params) => (
                <TextField {...params} />
              )}
            />
            :
            <></>
        }
      </Grid>

      {/** その他条件選択 */}
      <Grid className="section-divider" item={true} xs={12} style={{ marginTop: 10 }}>
        <Divider textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {t("labels.miscCondition")}
          </Typography>
        </Divider>
      </Grid>
      <Grid className="misc-select" item={true} xs={12}>
        {
          currentWeaponStat ?
            <Autocomplete
              multiple
              options={getMiscConditionList()}
              getOptionLabel={(option) => t(`labels.${option.label}`)}
              filterSelectedOptions
              onChange={selectMiscCondition}
              renderInput={(params) => (
                <TextField {...params} />
              )}
            />
            :
            <></>
        }
      </Grid>

      <Grid item={true} xs={12}>&nbsp;</Grid>

      {
        currentWeaponStat ?
          <DmgMotion monster={currentMonser} weapon={{ type: currentWeapon.type, filter: currentWeapon.filter, ...currentWeaponStat }}
            stats={stats} misc={currentMiscConditions} setIsLoading={props.setIsLoading} />
          :
          <></>
      }
    </>
  );
}