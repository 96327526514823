// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  styled,
  Grid,
  Stack,
  Typography,
  Select, MenuItem,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableCell, TableRow
} from '@mui/material';

export default function Part(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const { monster } = props;
  const [star, setStar] = React.useState(8);

  /**
   *  Custom functions
   */
  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.primary.light,
    }
  }));

  const selectStar = (e) => {
    setStar(e.target.value);
  };

  const getHitZones = (hitZones) => {
    let cutting = null;
    let blunt = null;
    let shooting = null;

    hitZones.forEach(h => {
      switch (h.attribute) {
        case "cutting":
          cutting = h.value;
          break;
        case "blunt":
          blunt = h.value;
          break;
        case "shooting":
          shooting = h.value;
          break;

        default:
          break;
      }
    });

    return `${cutting}/${blunt}/${shooting}`;
  };

  /**
   *  Custom variables
   */
  const nf = new Intl.NumberFormat('en-US');

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.monsterSpec")}</Typography>
      </Grid>

      <Grid className="monster-spec-wrapper" item={true} xs={12}>
        <Grid className="star-selector" item={true} xs={12}>
          <Select value={star} onChange={selectStar}>
            {
              [8, 9, 10].map(i =>
                <MenuItem value={i} key={i}>
                  <img className="menuIcon" src={`/images/${monster.imageFile}`} alt={monster.name} />
                  {[...Array(i - 5)].map((e, j) =>
                    <img className="purple-star" key={j} src="/images/purple-star.webp" alt="purple-star" />
                  )}
                </MenuItem>
              )
            }
          </Select>
        </Grid>

        <TableContainer className="monster-spec-table" component={Paper}>
          <Table size="small">
            <caption style={{ textAlign: "center" }}>
              <Typography variant="caption">{t("msg.valueForSolo")}</Typography>
            </caption>
            <TableHead>
              <StyledTableHeadRow>
                <TableCell align="center">{t("labels.part")}</TableCell>
                <TableCell align="center">{t("labels.resistance")}</TableCell>
                <TableCell align="center">
                  <Stack direction="column">
                    {t("labels.hitZone")}
                    <Typography variant="caption">({t("labels.cutting")}/{t("labels.blunt")}/{t("labels.shooting")})</Typography>
                  </Stack>
                </TableCell>
              </StyledTableHeadRow>
            </TableHead>
            <TableBody>
              {
                monster?.specs?.map(s =>
                  s.star === star ?
                    <React.Fragment key={s._id}>
                      <StyledTableRow>
                        <TableCell align="center">{t("labels.hp")}</TableCell>
                        <TableCell align="center">{nf.format(s.hp)}</TableCell>
                        <TableCell align="center">-</TableCell>
                      </StyledTableRow>
                      {
                        s.parts?.map(p =>
                          p.breakable ?
                            <StyledTableRow key={p._id}>
                              <TableCell align="center">{t(`parts.${p.name}`)}</TableCell>
                              <TableCell align="center">
                                <Stack direction="column">
                                  {nf.format(p.hp)}
                                  <Typography variant="caption">
                                    {`(${(p.hp / s.hp * 100).toFixed(0)}%)`}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="center">{getHitZones(p.hitZones)}</TableCell>
                            </StyledTableRow>
                            :
                            null
                        )
                      }
                    </React.Fragment>
                    :
                    null
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}