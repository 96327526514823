// import React libraries
import React from 'react';

export default function InArticle(props) {
  /**
   *  React Effects
   */
  React.useEffect(() => {
    //(window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [])

  /**
   *  Render the component
   */
  return (
    <>
      <ins className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-1328816617676539"
        data-ad-slot={props.dataAdSlot}>
      </ins>
    </>
  );
}