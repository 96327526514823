// import React libraries
import React from 'react';
// import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  // useTheme,
  Grid,
  Stack,
  // Autocomplete, TextField
} from '@mui/material';

export default function Filter(props) {
  /**
   *  React variables
   */
  // const { t } = useTranslation();
  const { data, filter } = props;

  /**
   *  Custome variables
   */
  // const theme = useTheme();

  /**
   *  Custom functions
   */
  const setFilterOptions = (newOption) => {
    filter.setOptions({ ...filter.options, ...newOption });
  };

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="stone-select-container" container={true}>
        <Stack className="stone-select" direction="row" spacing={1} justifyContent="center" alignItems="center">
          <img className={filter.options.stoneType === "all" ? "stoneIcon-selected" : "stoneIcon"}
            src={`/images/stones/all.png`} alt="all" onClick={() => setFilterOptions({ stoneType: "all" })} />
          {
            data.stones.map(s =>
              <img className={filter.options.stoneType === s.name ? "stoneIcon-selected" : "stoneIcon"}
                src={`/images/stones/${s.name}.png`} key={s.name} alt={s.name}
                onClick={() => setFilterOptions({ stoneType: s.name })} />
            )
          }
        </Stack>
      </Grid>

      {
        /**
        <Grid className="stone-skill-search" item={true} xs={12} alignItems="center" justifyContent="center">
        <Autocomplete
          multiple filterSelectedOptions
          options={data.skills} getOptionLabel={(option) => t(`skills.${option.skill.name}`)}
          onChange={selectSkills} style={{ maxWidth: 400, margin: "0 auto" }}
          renderInput={(params) => (
            <TextField {...params} label={t("labels.filterBySkills")} />
          )}
          renderOption={(props, option) =>
            <span {...props} style={{ color: theme.palette.driftstone[option.stoneType] }}>
              {option.skill.rare && <>&#9733;</>}
              {t(`skills.${option.skill.name}`)}
            </span>
          }
        />
      </Grid>
      */
      }
    </>
  );
}