// import third-party libraries
import {
  AppBar,
  Grid,
  Box,
  Toolbar,
  Typography,
  Link
} from '@mui/material';

// import custom CSS
import '../../css/footer.css';

export default function Footer() {
  /**
   *  Render the component
   */
  return (
    <AppBar className="footer" color="primary">
      <Toolbar variant="dence">
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="caption">
                &copy; {new Date().getFullYear()} <Link href="https://discord.gg/tfnPbwNWhd" target="_blank">モンハンNow攻略Discord</Link> 分析班
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}