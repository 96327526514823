// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Select, MenuItem,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  FormControl, InputLabel,
  Typography,
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components

// import custom CSS

export default function HitPoint(props) {
  /**
   *  React variables
   */
  const [monsters, setMonsters] = React.useState([]);
  const [grade, setGrade] = React.useState(5);
  const [skillLevel, setSkillLevel] = React.useState(0);
  const { t } = useTranslation();

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonsters();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getMonsters = async () => {
    const res = await axios.get(`/monsters?details=true`);
    setMonsters(res.data);
  };

  const calculateNumOfAmmos = (spec) => {
    if (!spec?.sleep?.resistance) {
      return "-";
    }

    const baseElementalValue = LB_BASE_ELEMENT_VAL.find(ev => ev.grade === grade).value;
    return Math.ceil(spec.sleep.resistance / (3 * (baseElementalValue + skillLevel)));
  };

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    }
  }));

  /**
   *  Custom variables
   */
  const LB_BASE_ELEMENT_VAL = [
    { grade: 5, value: 150 },
    { grade: 6, value: 180 },
    { grade: 7, value: 195 },
    { grade: 8, value: 210 },
    { grade: 9, value: 225 },
    { grade: 10, value: 240 },
  ];

  const SLEEP_SKILL = [0, 50, 75, 100, 125, 150];

  /**
   *  Render the component
   */
  return (
    <Grid item={true} xs={12} alignItems="center" justifyContent="center">
      <Typography variant="h6" color="text.primary" align="center" sx={{ marginTop: 1 }}>
        睡眠ボウガン必要弾数早見表
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ marginBottom: 2 }}>
        &#42;初回睡眠に必要な弾数
      </Typography>

      <Stack direction="row" spacing={2} sx={{ maxWidth: 500, marginBottom: 1, marginX: "auto" }}>
        <FormControl size="small" fullWidth>
          <InputLabel>{t("labels.weaponGrade")}</InputLabel>
          <Select value={grade} label={t("labels.weaponGrade")}
            onChange={(event) => setGrade(event.target.value)}>
            {
              LB_BASE_ELEMENT_VAL.map(lb =>
                <MenuItem key={lb.grade} value={lb.grade}>{lb.grade}</MenuItem>
              )
            }
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth>
          <InputLabel>{t("labels.statusCondition.sleepLevel")}</InputLabel>
          <Select value={skillLevel} label={t("labels.sleepLevel")}
            onChange={(event) => setSkillLevel(event.target.value)}>
            {
              SLEEP_SKILL.map((sl, i) =>
                <MenuItem key={sl} value={sl}>{i}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Stack>

      <Paper className="nms-paper">
        <TableContainer className="nms-table" sx={{ maxWidth: 500, marginBottom: 5, marginX: "auto" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <StyledTableHeadRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">&#9733;8</TableCell>
                <TableCell align="center">&#9733;9</TableCell>
                <TableCell align="center">&#9733;10</TableCell>
              </StyledTableHeadRow>
            </TableHead>
            <TableBody>
              {
                monsters?.map(m =>
                  m.specs[0].sleep &&
                  <StyledTableRow key={m._id}>
                    <TableCell component="th" scope="row" align="center">
                      <img className="monsterIcon" src={`/images/${m.imageFile}`} alt={m.name} style={{ width: 30 }} />
                    </TableCell>
                    <TableCell align="center">{calculateNumOfAmmos(m.specs[0])}</TableCell>
                    <TableCell align="center">{calculateNumOfAmmos(m.specs[1])}</TableCell>
                    <TableCell align="center">{calculateNumOfAmmos(m.specs[2])}</TableCell>
                  </StyledTableRow>

                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}