// import React libraries
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Box, AppBar, Toolbar,
  Stack,
  Typography,
  Button
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

// import custom CSS
import '../../css/common.css';

export default function Maintenance() {
  /**
   *  React variables
   */
  const { t } = useTranslation();

  /**
   *  Render the component
   */
  return (
    <Grid className="maintenance" container={true} alignItems="center" justifyContent="center" sx={{ bgcolor: "primary.light" }}>
      <Box sx={{ width: "100%", position: "absolute", top: 0 }}>
        <AppBar className="navbar" position="static" color="primary">
          <Toolbar>
            <Typography className="logo" variant="h6" component="div" textAlign="center" color="white" >
              {t("labels.siteName")}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <img src="/maintenance.webp" alt="maintenance" />
        <Typography variant="h5" color="text.primary">
          {t("msg.underMaintenance")}
        </Typography>
        <Button variant="contained" href="/" startIcon={<CachedIcon />}>{t("button.reload")}</Button>
      </Stack>
    </Grid>
  );
}