// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { Grid, Typography, Divider } from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import Total from './Total';
import Filter from './Filter';
import SkillPercent from './SkillPercent';
import BottomNav from '../common/BottomNav';

// import custom CSS
import '../../css/driftstone.css';

export default function Driftstone(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();

  const [driftstones, setDriftstones] = React.useState([]);
  const [driftstonesData, setDriftstonesData] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [options, setOptions] = React.useState({ stoneType: "all", skill: null });

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getDriftstones();
      await getDriftstonesData();
      await getSkills();
      props.setIsLoading(false);
    })();
  }, [])

  /**
   *  Custom functions
   */
  const getDriftstones = async () => {
    const res = await axios.get(`/items?type=driftstone`);
    setDriftstones(res.data);
  };

  const getDriftstonesData = async () => {
    const res = await axios.get(`/driftstones`);
    setDriftstonesData(res.data.filter(d => !d.isGem));
  };

  const getSkills = async () => {
    const res = await axios.get(`/driftstonesMaster`);
    setSkills(res.data);
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.driftSkillPercentage")}</Typography>
      </Grid>

      <Total data={driftstonesData} />

      <Grid className="driftstone-wrapper" container={true} justify="center">
        <Filter data={{ stones: driftstones, skills: skills }} filter={{ options, setOptions }} />
      </Grid>

      <Grid item={true} xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid className="driftstone-wrapper" container={true} justify="center">
        <SkillPercent data={{ records: driftstonesData, skills: skills }} filter={{ options, setOptions }} />
      </Grid>


      <BottomNav admin={false} />

    </Grid>
  );
}