// import React libraries
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

// import third-party libraries
import { Grid } from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import HeaderPage from './Header';
import InArticleAd from '../ads/InArticle';
import DropData from './DropData';
import DropImg from './DropImg';
import Part from './Part';
import BottomNav from '../common/BottomNav';

// import custom CSS
import '../../css/monster.css';

export default function Monster(props) {
  /**
   *  React variables
   */
  const params = useParams();
  const location = useLocation();
  const [monsterDrop, setMonsterDrop] = React.useState([]);
  const [monsterSpec, setMonsterSpec] = React.useState([]);

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonsterDrop();
      await getMonsterSpec();
      props.setIsLoading(false);
    })();
  }, [location])

  /**
   *  Custom functions
   */
  const getMonsterDrop = async () => {
    const res = await axios.get(`/monsters/drop/${params.monsterName}`);
    setMonsterDrop(res.data);
  };
  const getMonsterSpec = async () => {
    const res = await axios.get(`/monsters/${params.monsterName}`);
    setMonsterSpec(res.data);
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <HeaderPage />

      <Part monster={monsterSpec} />
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <DropData monster={monsterDrop} />

      <InArticleAd dataAdSlot="6172942903" />
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <DropImg monster={monsterDrop} />

      <InArticleAd dataAdSlot="5887502873" />
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <BottomNav admin={false} />

    </Grid>
  );
}