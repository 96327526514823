// import React libraries
import React from 'react';
import { useParams } from 'react-router-dom';

// import third-party libraries
import {
  Grid,
  Typography
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import ArticleCardPage from './AricleCard';
import BottomNavPage from '../common/BottomNav';

// import custom CSS
import '../../css/article.css';

export default function Article(props) {
  /**
   *  React variables
   */
  const params = useParams();
  const [article, setArticle] = React.useState({});

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getArticle(params.id);
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getArticle = async (articleId) => {
    const res = await axios.get(`/article/${articleId}`);
    if (res.data) {
      setArticle(res.data);
    }
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">お知らせ#{article.id}</Typography>
      </Grid>

      <Grid className="article-viewer" item={true} xs={12}>
        <ArticleCardPage article={article} />
      </Grid>

      <BottomNavPage />
    </Grid>
  );
}