// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Card, CardContent,
  Divider,
  List, ListItem,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Button,
  Typography,
  Alert, AlertTitle,
  Link,
  Accordion, AccordionSummary, AccordionDetails,
  FormControl, InputLabel, Select, MenuItem,
  TextField
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// import custom components
import MonsterStatus from './MonsterStatus';

// import custom CSS

export default function Paralysis(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const statusFormulaRef = React.useRef(null);
  const monsterStatusRef = React.useRef(null);
  const statusEnhanceRef = React.useRef(null);
  const statusOverrideRef = React.useRef(null);
  const statusCalculatorRef = React.useRef(null);

  const eAttackRef = React.useRef(0);
  const [weapon, setWeapon] = React.useState("sns");
  const thresholdRef = React.useRef(0);
  const [statusCount, setStatusCount] = React.useState(1);
  const [statusCalcResult, setStatusCalcResult] = React.useState(0);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */
  React.useEffect(() => {
    calculate();
  }, [weapon, statusCount]);

  /**
   *  Custom functions
   */
  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const calculate = () => {
    const threshold = thresholdRef.current.value * (2 ** (statusCount - 1)) - (2 ** (statusCount - 1) - 1);
    const eattack = eAttackRef.current.value * WEAPON_MODIFIER.find(w => w.weapon === weapon).modifier;

    if (!isNaN(threshold) && !isNaN(eattack) && eattack !== 0) {
      setStatusCalcResult(Math.ceil(threshold / eattack));
    }
  };

  /**
   *  Custome variables
   */
  const AGENDA = [
    { label: "statusFormula", ref: statusFormulaRef },
    { label: "monsterStatusAndDamage", ref: monsterStatusRef },
    { label: "statusEnhance", ref: statusEnhanceRef },
    { label: "statusOverride", ref: statusOverrideRef },
    { label: "statusCalculator", ref: statusCalculatorRef }
  ];
  const WEAPON_MODIFIER = [
    { weapon: "sns", modifier: 1 },
    { weapon: "db", modifier: 0 },
    { weapon: "gs", modifier: 2 },
    { weapon: "ls", modifier: 0 },
    { weapon: "hammer", modifier: 1.6 },
    { weapon: "lance", modifier: 0 },
    { weapon: "gl", modifier: 1.5 },
    { weapon: "cb", modifier: 1.3 },
    { weapon: "lb", modifier: 0 },
    { weapon: "hb", modifier: 0 },
    { weapon: "bow", modifier: 0 },
  ];

  /**
   *  Render the component
   */
  return (
    <>
      <Card>
        <CardContent>
          {
            /**
             * 目次
             */
          }
          <Typography variant="h6">{t("labels.agenda")}</Typography>
          <Stack direction="column" spacing={1}>
            {
              AGENDA.map(a =>
                <Stack direction="row" spacing={0.5} key={a.label}>
                  <img className="status-icon" src="/images/paralysis.webp" alt="paralysis" />
                  <Link variant="body2" underline="none" href={`#${a.label}`} onClick={() => a.ref.current.scrollIntoView()}>
                    {t(`menu.header.${a.label}`)}
                  </Link>
                </Stack>
              )
            }
          </Stack>

          {
            /**
             * 蓄積値の計算式
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusFormulaRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[0].label}`)}
            </Typography>
          </Divider>
          <Grid item={true} xs={12}>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ marginBottom: 2, fontWeight: "bold", bgcolor: "primary.light" }}>
              <Typography variant="h6">{t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.paralysis") })}</Typography>
              <ClearIcon />
              <Typography variant="h6">{t("labels.statusCondition.weaponModifier")}</Typography>
            </Stack>
            <Typography variant="body2">
              {`${t("labels.statusCondition.paralysis")}${t("labels.statusCondition.formulaIntro")}`}
            </Typography>
            <Typography variant="body2">
              {t("labels.statusCondition.formulaExclude")}
            </Typography>
            <List dense={true}>
              <ListItem>
                <Typography variant="caption">{t("labels.pattackValue")}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption">{`${t("labels.move")}${t("labels.motionValue")}`}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption">{t("menu.header.statusDamage")}</Typography>
              </ListItem>
            </List>
            <Typography variant="body2">
              {t("labels.statusCondition.formulaExclude2")}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 2 }}>
              {t("labels.statusCondition.formulaExplain")}
            </Typography>

            <Button variant="text" startIcon={<QuestionMarkIcon />}>
              {t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.paralysis") })}
            </Button>
            <Typography variant="body2">
              {t("labels.statusCondition.elementValueExplain", { status: t("labels.statusCondition.paralysis") })}
            </Typography>

            <Button variant="text" startIcon={<QuestionMarkIcon />}>
              {t("labels.statusCondition.weaponModifier")}
            </Button>
            <Typography variant="body2">
              {t("labels.statusCondition.weaponModifierExplain")}
            </Typography>
            <TableContainer component={Paper} sx={{ maxWidth: 250 }}>
              <Table size="small">
                <TableHead>
                  <StyledTableHeadRow>
                    <TableCell align="left">{t("labels.weaponType")}</TableCell>
                    <TableCell align="center">{t("labels.statusCondition.modifier")}</TableCell>
                  </StyledTableHeadRow>
                </TableHead>
                <TableBody>
                  {
                    WEAPON_MODIFIER.map(w =>
                      <TableRow key={w.weapon}>
                        <TableCell align="left">{t((`labels.${w.weapon}`))}</TableCell>
                        <TableCell align="center">{w.modifier === 0 ? "N/A" : w.modifier.toFixed(1)}</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="caption">
              *N/A={t("labels.statusCondition.noWeapon", { status: t("labels.statusCondition.paralysis") })}
            </Typography>

            <Alert severity="error">
              <Typography variant="body2">{t("labels.statusCondition.formulaOverflow")}</Typography>
              <Typography variant="body2">{t("labels.statusCondition.formulaOverflow2", { status: t("labels.statusCondition.paralysis") })}</Typography>
            </Alert>

          </Grid>

          {
            /**
             * モンスター別耐性値と与ダメージ
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={monsterStatusRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[1].label}`)}
            </Typography>
          </Divider>

          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {t("labels.statusCondition.monsterIntro", { status: t("labels.statusCondition.paralysis") })}
          </Typography>
          <Typography variant="body2">
            {t("labels.statusCondition.monsterParalysis")}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {t("labels.statusCondition.monsterParalysis2")}
          </Typography>

          <MonsterStatus monsters={props.monsters} statusType={props.statusType} />

          {
            /**
             * 耐性値の増加
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusEnhanceRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[2].label}`)}
            </Typography>
          </Divider>

          <Typography variant="body2">
            {t("labels.statusCondition.statusEnhanceIntro", { status: t("labels.statusCondition.paralysis"), modifier: 2 })}
          </Typography>
          <Typography variant="caption" color="grey">
            {t("labels.statusCondition.statusEnhanceFormula", { status: t("labels.statusCondition.paralysis"), modifier: 2 })}
          </Typography>
          <Typography variant="body2">
            {t("labels.statusCondition.statusEnhanceWhen", { status: t("labels.statusCondition.paralysis") })}
          </Typography>
          <Alert severity="info">{t("labels.statusCondition.statusEnhanceLinear")}</Alert>
          <Typography variant="body2" sx={{ marginTop: 2, textDecoration: "line-through" }}>
            {t("labels.statusCondition.statusEnhanceCondition", { status: t("labels.statusCondition.paralysis") })}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2, textDecoration: "line-through" }}>
            {t("labels.statusCondition.statusEnhanceCondition2", { status: t("labels.statusCondition.paralysis") })}
          </Typography>
          <Alert variant="outlined" color="warning" icon={false}>
            <AlertTitle>7/9サーバリリースで修正済 (7/25追記)</AlertTitle>
            いずれの場合も耐性値は同様に上昇します。
          </Alert>

          {
            /**
             * 状態異常の上書き
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusOverrideRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[3].label}`)}
            </Typography>
          </Divider>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {t("labels.statusCondition.statusOverrideIntro", { status: t("labels.statusCondition.paralysis") })}
          </Typography>
          <Typography variant="body2">
            {t("labels.statusCondition.statusOverrideExplain")}
          </Typography>

          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
              <Typography variant="body2" style={{ textDecoration: "line-through" }}>
                {t("labels.statusCondition.paralysisOverride2Summary")}
              </Typography>
              <Typography variant="body2" color="blue" style={{ marginLeft: 10 }}>
                7/9サーバリリースで修正済 (7/25追記)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="blue">
                上書きの場合でも、通常通り（現在の耐性値の）2倍に上昇します。
              </Typography>

              <Typography variant="body2" color="gray" style={{ textDecoration: "line-through" }}>
                以降、麻痺状態の時間＝拘束時間とします。
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 2 }} color="gray" style={{ textDecoration: "line-through" }}>
                拘束時間中は<span style={{}}>麻痺耐性値が維持されます</span>が、麻痺が上書きされても<span style={{}}>拘束時間は延びません</span>。
                <span style={{ fontSize: 11 }}>（タイミングよく拘束が終わる瞬間に次の麻痺が入ると、拘束時間が延びてるように見えることはあります）</span>
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }} color="gray" style={{ textDecoration: "line-through" }}>
                耐性値の上昇有無は麻痺状態になった瞬間に決まるため、拘束時間終了間際に麻痺状態にできれば、麻痺解除後も耐性値は維持されたままとなります。
                <span style={{ fontSize: 11 }}>（次の麻痺耐性値は上昇します）</span>
              </Typography>

              <Typography variant="body2" sx={{ marginTop: 1 }} color="gray" style={{ textDecoration: "line-through" }}>
                以上のことを踏まえた具体例：
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 2 }} color="gray" style={{ textDecoration: "line-through" }}>条件</Typography>
              <Stack direction="row" spacing={2} sx={{ marginLeft: 3 }} color="gray" style={{ textDecoration: "line-through" }}>
                <Stack direction="column">
                  <Typography variant="caption" >麻痺耐性値</Typography>
                  <Typography variant="caption">麻痺属性値</Typography>
                  <Typography variant="caption">拘束時間</Typography>
                </Stack>
                <Stack direction="column">
                  <Typography variant="caption">1645</Typography>
                  <Typography variant="caption">417</Typography>
                  <Typography variant="caption">10秒</Typography>
                </Stack>
              </Stack>

              <TableContainer className="monster-status-table" component={Paper} sx={{ marginBottom: 2 }}>
                <Table size="small" color="gray" style={{ textDecoration: "line-through" }}>
                  <caption style={{ fontSize: 12, textDecoration: "line-through" }} color="gray">
                    ※拘束時間はモンスターによって異なります。拘束時間が短く、かつ高い耐性値を持つモンスターの麻痺上書きは現実的ではありません。
                  </caption>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell align="center">残り秒数</TableCell>
                      <TableCell align="center">イベント</TableCell>
                      <TableCell align="center">麻痺耐性値</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">75</TableCell>
                      <TableCell align="center">戦闘開始</TableCell>
                      <TableCell align="center">1倍</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">70</TableCell>
                      <TableCell align="center">1回目麻痺</TableCell>
                      <TableCell align="center">1倍→2倍</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">62</TableCell>
                      <TableCell align="center">2回目麻痺</TableCell>
                      <TableCell align="center" sx={{}}>2倍→2倍</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">60</TableCell>
                      <TableCell align="center">麻痺解除</TableCell>
                      <TableCell align="center">2倍→2倍</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">50</TableCell>
                      <TableCell align="center">3回目麻痺</TableCell>
                      <TableCell align="center" sx={{}}>2倍→4倍</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="body2" color="gray" style={{ textDecoration: "line-through" }}>
                上記の例では、拘束時間が残り70〜60秒になります。
              </Typography>
              <Typography variant="body2" color="gray" style={{ textDecoration: "line-through" }}>
                2回目麻痺が残り時間59秒以降に発生した場合は、3回目麻痺に必要な耐性値は4倍に上昇します。
              </Typography>

            </AccordionDetails>
          </Accordion>

          {
            /**
             * 蓄積値計算ツール
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusCalculatorRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[4].label}`)}
            </Typography>
          </Divider>

          <Grid container={true}>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>{t("labels.weaponType")}</InputLabel>
                <Select value={weapon} label={t("labels.weaponType")} onChange={(event) => setWeapon(event.target.value)}>
                  {
                    WEAPON_MODIFIER.filter(w => w.modifier > 0).map(w =>
                      <MenuItem key={w.weapon} value={w.weapon}>{t((`labels.${w.weapon}`))}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <TextField type="number" label={t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.paralysis") })}
                onChange={calculate} inputRef={eAttackRef} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <TextField type="number" label={`${t("labels.statusCondition.paralysis")}${t("labels.statusCondition.threshold")}`}
                onChange={calculate} inputRef={thresholdRef} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>{t("labels.statusCondition.statusCount", { status: t("labels.statusCondition.paralysis") })}</InputLabel>
                <Select value={statusCount} label={t("labels.statusCondition.statusCount", { status: t("labels.statusCondition.paralysis") })}
                  onChange={(event) => setStatusCount(event.target.value)}>
                  {
                    [...Array(7)].map((e, i) =>
                      <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: 3, marginBottom: 10 }}>
            <Typography variant="h4" sx={{ marginRight: 1 }}>
              {statusCalcResult}
            </Typography>
            <Typography variant="body2">
              {t("labels.statusCondition.statusCalcResult", { status: t("labels.statusCondition.paralysis") })}
            </Typography>
          </Stack>

        </CardContent>
      </Card>
    </>
  );
}