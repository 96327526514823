// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  useTheme,
  Grid,
  Stack,
  Collapse,
  Button,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function Total(props) {
  /**
   *  Custome variables
   */
  const defaultPercents = {
    rare: { count: 0, attempt: 0, percent: (0).toFixed(1) },
    specific: { count: 0, attempt: 0, percent: (0).toFixed(1) },
    common: { count: 0, attempt: 0, percent: (0).toFixed(1) }
  };

  /**
   *  React variables
   */
  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = props;
  const [showTotal, setShowTotal] = React.useState(false);
  const [percents, setPercents] = React.useState(defaultPercents);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    calculatePercentages();
  }, [data])


  /**
   *  Custom functions
   */
  const calculatePercentages = () => {
    const _percents = structuredClone(percents);

    const totalRareCount = data.filter(d => d.skill.rare).length;
    const totalNormal = data.filter(d => !d.skill.rare);
    const commonCount = totalNormal.filter(d => d.skill.common).length;
    const specificCount = totalNormal.length - commonCount;

    _percents.rare = {
      count: totalRareCount,
      attempt: data.length,
      percent: (totalRareCount / data.length * 100).toFixed(1)
    };

    _percents.specific = {
      count: specificCount,
      attempt: data.length,
      percent: (specificCount / data.length * 100).toFixed(1)
    };

    _percents.common = {
      count: commonCount,
      attempt: data.length,
      percent: (commonCount / data.length * 100).toFixed(1)
    };

    setPercents(_percents);
  };

  /**
   *  Render the component
   */
  return (
    <Grid className="stone-total-container" container={true}>
      <Grid item={true} xs={12}>
        <Stack className="stone-select" direction="column" spacing={1} justifyContent="center" alignItems="center">
          <Button variant="text" size="small" sx={{ width: "200px" }}
            startIcon={showTotal ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={() => setShowTotal(!showTotal)}>
            {t(showTotal ? `labels.hideTotal` : `labels.showTotal`)}
          </Button>

          <Collapse in={showTotal} timeout="auto" unmountOnExit >
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="body2" color="text.primary">{t("labels.rareSkillRate")}:</Typography>
                <Typography variant="body2" color="text.primary">
                  <span style={{ fontWeight: 700, fontSize: 18, marginRight: 2, color: theme.palette.primary.main }}>
                    {percents.rare.percent}
                  </span>
                  {`% (${percents.rare.count}/${percents.rare.attempt})`}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="body2" color="text.primary">{t("labels.specificNormalSkillRate")}:</Typography>
                <Typography variant="body2" color="text.primary">
                  <span style={{ fontWeight: 700, fontSize: 18, marginRight: 2, color: theme.palette.primary.main }}>
                    {percents.specific.percent}
                  </span>
                  {`% (${percents.specific.count}/${percents.specific.attempt})`}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="body2" color="text.primary">{t("labels.commonNormalSkillRate")}:</Typography>
                <Typography variant="body2" color="text.primary">
                  <span style={{ fontWeight: 700, fontSize: 18, marginRight: 2, color: theme.palette.primary.main }}>
                    {percents.common.percent}
                  </span>
                  {`% (${percents.common.count}/${percents.common.attempt})`}
                </Typography>
              </Stack>
            </Stack>
          </Collapse>

        </Stack>
      </Grid>
    </Grid>
  );
}