// import third-party libraries
import {
  Stack,
  Button
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';

export default function BottomNav(props) {
  /**
   *  Render the component
   */
  return (
    <Stack className="bottom-nav" direction="row" justifyContent="center" alignItems="center" spacing={2}>
      {
        props.admin ?
          <Button variant="contained" href={`/admin?token=${props.adminToken}`} startIcon={<AdminPanelSettingsIcon />}>管理画面に戻る</Button>
          :
          <></>
      }
      <Button variant="outlined" href="/" startIcon={<HomeIcon />}>ホーム画面に戻る</Button>
    </Stack>
  );
}