// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import 'moment/locale/ja';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Divider,
  Typography,
  Button,
  Modal, Fade, Backdrop
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import PartModal from '../monsters/Part';
import DmgSubMotion from './DmgSubMotion';

// import custom CSS
import '../../css/motion.css';

export default function DmgMotion(props) {
  /**
   *  React variables
   */
  const [motions, setMotions] = React.useState([]);
  const [showCritical, setShowCritical] = React.useState(false);
  const [openMonsterSpec, setOpenMonsterSpec] = React.useState(false);
  const [showStats, setShowStats] = React.useState(false);
  const { t } = useTranslation();
  const { monster, weapon, stats, misc } = props;

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMotions();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const rearrangeParts = () => {
    if (!monster) return [];

    const parts = monster.specs[0].parts;
    let _rearrangedParts = parts.map(p =>
      p.name.includes("left-") ? { ...p, name: p.name.replace("left-", "") } : p
    );
    _rearrangedParts = _rearrangedParts.filter(p => !p.name.includes("right-"));

    const rearrangedParts = [];

    _rearrangedParts.forEach(r => {
      const _part = { _id: r._id, name: [r.name], hitZones: r.hitZones, armor: r.armor };
      const index = rearrangedParts.findIndex(v =>
        v.hitZones[0].value === r.hitZones[0].value
        && v.hitZones[1].value === r.hitZones[1].value
        && v.hitZones[2].value === r.hitZones[2].value
      )

      if (index === -1) {
        rearrangedParts.push(_part);
      } else {
        rearrangedParts[index].name.push(r.name);
      }
    });

    partsList = rearrangedParts;
    return rearrangedParts;
  };

  const getMotions = async () => {
    const res = await axios.get(`/motions`);
    setMotions(res.data);
  };

  const showMoton = (motion) => {
    if (!motion.filter || !weapon.filter) {
      return true;
    }

    return weapon.filter.includes(motion.filter);
  };

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const calculateModifier = () => {
    let modifier = stats.modifier;

    misc.forEach(m => {
      modifier *= m.modifier;
    });

    return roundNumber(modifier, 2, true);
  };

  const roundNumber = (value, digit, epsilon) => {
    let result = null;
    const exp = 10 ** digit;
    if (epsilon) {
      result = Math.round((value + Number.EPSILON) * exp) / exp;
    } else {
      result = Math.round(value * exp) / exp;
    }

    return result;
  };

  /**
   *  Custome variables
   */
  let partsList = [];
  let bgColorIndex = 0;

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="damage-calc-wrapper" container={true}>

        <Stack className="damage-table-filter" direction="row" spacing={1}>
          <Button variant={showCritical ? "contained" : "outlined"} size="small" onClick={() => setShowCritical(!showCritical)}>
            {t(`labels.${showCritical ? "hideCritical" : "showCritical"}`)}
          </Button>
          <Button variant="outlined" size="small" onClick={() => setOpenMonsterSpec(true)}>
            {t("labels.openMonsterSpec")}
          </Button>
          <Button variant={showStats ? "contained" : "outlined"} size="small" onClick={() => setShowStats(!showStats)}>
            {t(`labels.${showStats ? "hideStats" : "showStats"}`)}
          </Button>
        </Stack>

        <Modal open={openMonsterSpec} onClose={() => setOpenMonsterSpec(false)}
          closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
          <Fade in={openMonsterSpec}>
            <Paper className="info-modal" sx={{ boxShadow: 24 }}>
              <PartModal monster={monster} />
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Button variant="text" size="small" startIcon={<CloseIcon />} onClick={() => setOpenMonsterSpec(false)}>
                  {t("labels.closeModal")}
                </Button>
              </Stack>
            </Paper>
          </Fade>
        </Modal>

        <Grid item={true} xs={12} display={showStats ? "inline-block" : "none"}>
          <Divider textAlign="center">
            <Typography variant="body2" color="text.secondary">
              {t("labels.status")}
            </Typography>
          </Divider>
          <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
            <Stack direction="column" spacing={1} alignItems="flex-end">
              <Typography variant="caption" color="text.primary">{t("labels.pattackValue") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.eattackValue") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.combinedAttackValue") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.affinity") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.weaknessAffinity") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{`${t("labels.damageModifier")}(${t("labels.modifierException")}):`}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.criticalModifiers") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.blastModifier") + ":"}</Typography>
              <Typography variant="caption" color="text.primary">{t("labels.spModifier") + ":"}</Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography variant="caption" color="text.primary">{stats.attack}</Typography>
              <Typography variant="caption" color="text.primary">{stats.eattack}</Typography>
              <Typography variant="caption" color="text.primary">{stats.attack + stats.eattack}</Typography>
              <Typography variant="caption" color="text.primary">{roundNumber(stats.affinity * 100, 2, true)}%</Typography>
              <Typography variant="caption" color="text.primary">{roundNumber(stats.weaknessAffinity * 100, 2, true)}%</Typography>
              <Typography variant="caption" color="text.primary">x{calculateModifier(stats.modifier)}</Typography>
              <Typography variant="caption" color="text.primary">
                x{roundNumber(stats.criticalModifier[0], 2, true)}, x{roundNumber(stats.criticalModifier[1], 2, true)}
              </Typography>
              <Typography variant="caption" color="text.primary">x{roundNumber(stats.blastModifier, 2, true)}</Typography>
              <Typography variant="caption" color="text.primary">x{roundNumber(stats.spModifier, 2, true)}</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item={true} xs={12} alignItems="center" justifyContent="center">
          <TableContainer component={Paper} sx={{ overflowX: "auto", maxHeight: 500 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <StyledTableHeadRow>
                  <TableCell align="left" sx={{ minWidth: 150, position: "sticky", zIndex: 100, left: 0 }}>
                    <Typography variant="caption">
                      {t("labels.move")}
                    </Typography>
                  </TableCell>
                  {
                    rearrangeParts().map(p =>
                      <TableCell align="center" key={p._id} sx={{ minWidth: 80 }}>
                        <Stack direction="column">
                          {
                            p.name.map(n =>
                              <Typography variant="caption" key={n}>
                                {t(`parts.${n}`)}
                              </Typography>
                            )
                          }
                        </Stack>
                      </TableCell>
                    )
                  }
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {
                  motions?.filter(m => m.weapon === weapon.type).map(m =>
                    showMoton(m) ?
                      <DmgSubMotion key={m._id} row={m} index={0} bgColor={++bgColorIndex % 2 === 0 ? "white" : "primary.light"}
                        weapon={weapon} partsList={partsList} stats={stats} misc={misc} showCritical={showCritical} />
                      :
                      <React.Fragment key={m._id}></React.Fragment>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid >
    </>
  );
}