// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Box,
  AppBar,
  Toolbar,
  Badge,
  Menu, MenuItem, ListItemIcon, ListItemText,
  IconButton,
  Typography,
  Divider,
  Link
} from '@mui/material';
import Moment from 'moment-timezone';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import CalculateIcon from '@mui/icons-material/Calculate';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import NotificationsIcon from '@mui/icons-material/Notifications';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom CSS
import '../../css/header.css';

export default function Navbar() {
  /**
   *  React variables
   */
  const axios = React.useContext(AxiosContext);
  const { t } = useTranslation();
  const [news, setNews] = React.useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    getNotifications();
  }, []);

  /**
   *  Custom functions
   */
  const getNotifications = () => {
    const params = { limit: process.env.REACT_APP_NOTIFICATION_LIMIT }
    axios.get("/articles", { params }).then(res => {
      if (Array.isArray(res.data)) {
        const today = Moment();
        const updates = res.data.filter(d => today.diff(Moment(d.updatedAt), "days") < process.env.REACT_APP_NOTIFICATION_DURATION);
        setNews(updates.length === 0);
      }
    });
  }

  const menuOpenHandler = (e) => {
    setMenuAnchorEl(e.currentTarget);
  }

  const menuCloseHandler = () => {
    setMenuAnchorEl(null);
  }

  /**
   *  Render the component
   */
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="navbar" position="static" color="secondary">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={menuOpenHandler}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={menuCloseHandler}
          >
            <MenuItem component="a" href="/" onClick={menuCloseHandler}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText>{t("menu.home")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem component="a" href="/motions" onClick={menuCloseHandler}>
              <ListItemIcon><SearchIcon /></ListItemIcon>
              <ListItemText>{t("menu.motions")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem component="a" href="/calculator" onClick={menuCloseHandler}>
              <ListItemIcon>
                <CalculateIcon />
              </ListItemIcon>
              <ListItemText>{t("menu.calculator")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem component="a" href="/status" onClick={menuCloseHandler}>
              <ListItemIcon>
                {
                  Moment("2024-08-20").diff(Moment(), "days") > 0 ?
                    <Badge color="error" variant="dot">
                      <VaccinesIcon />
                    </Badge>
                    :
                    <VaccinesIcon />
                }
              </ListItemIcon>
              <ListItemText>{t("menu.status")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem component="a" href="/driftstones" onClick={menuCloseHandler}>
              <ListItemIcon><SmartToyIcon /></ListItemIcon>
              <ListItemText>{t("menu.driftstones")}</ListItemText>
            </MenuItem>
          </Menu>

          <Typography className="logo" variant="h6" component="div" textAlign="center" >
            <Link href="/" underline="none">
              {t("labels.siteName")}
            </Link>
          </Typography>

          <IconButton size="large" color="inherit" href="/notifications">
            <Badge variant="dot" color="error" invisible={news}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}