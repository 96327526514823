// import React libraries
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import third-party libraries
import {
  Grid,
  Typography,
  Stack,
  Button
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ArticleIcon from '@mui/icons-material/Article';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HomeIcon from '@mui/icons-material/Home';

// import custom CSS
import '../../css/admin.css';

export default function Admin(props) {
  /**
   *  React variables
   */
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = React.useState(false);

  /**
   *  React Effects
   */
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);
      checkToken();
    })();
  }, []);

  /**
   *  Custom functions
   */
  const checkToken = async () => {
    if (query.get("token") !== process.env.REACT_APP_ADMIN_TOKEN) {
      navigate("/");
    } else {
      setIsAdmin(true);
    }
  };

  const buttonClickHander = (link) => {
    if (link !== "/") {
      props.setAdminToken(query.get("token"));
    }
    navigate(link);
  };

  /**
   *  Render the component
   */
  return (

    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{isAdmin ? "管理画面" : ""}</Typography>
      </Grid>
      {
        isAdmin ?
          <Stack className="control-panel" direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Button color="success" variant="contained" startIcon={<DownloadIcon />} onClick={() => { buttonClickHander("/admin/import") }}>
              データインポート
            </Button>
            <Button variant="contained" startIcon={<ArticleIcon />} onClick={() => { buttonClickHander("/admin/createArticle") }}>
              お知らせ記事作成
            </Button>
            <Button variant="contained" startIcon={<ModeEditIcon />} onClick={() => { buttonClickHander("/admin/listArticle") }}>
              お知らせ記事編集
            </Button>
            <Button variant="outlined" startIcon={<HomeIcon />} href="/">
              ホーム画面に戻る
            </Button>
          </Stack>
          :
          <></>
      }
    </Grid>
  );
}
