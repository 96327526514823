// import React libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

// import third-party libraries
import {
  CssBaseline,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Divider
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import BottomNavPage from '../common/BottomNav';

export default function Import(props) {
  /**
   *  React variables
   */
  const navigate = useNavigate();
  const [url, setUrl] = React.useState("");
  const [imported, setImported] = React.useState(null);

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const urlChange = event => {
    setUrl(event.target.value);
  }

  const importHandler = async () => {
    if (!url.startsWith("https://")) {
      alert("正しいURLではないウホ.....");
      return;
    }
    props.setIsLoading(true);
    await importData();
    props.setIsLoading(false);
  };

  const importData = async () => {
    const res = await axios.put(`/system/importData`, { url, token: props.adminToken });

    setImported(res.data);
  };

  const publishHandler = async () => {
    props.setIsLoading(true);
    const newData = {
      total: imported.total._id,
      monsterDrops: imported.monsterDrops.map(m => m._id)
    }
    await publishData(newData);
  };

  const publishData = async (newData) => {
    const res = await axios.post(`/system/publishData`, { newData, token: props.adminToken });
    props.setIsLoading(false);

    if (res.data.result) {
      alert("データが公開されたウホ！");
      navigate("/");
    } else {
      alert("サーバエラーで公開に失敗したウホ...\n管理人に問い合わせるウホ...");
    }
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <CssBaseline />
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={8} align="center">
        <TextField className="spread-url" label="スプレッドシートのURL" id="outlined-size-small" size="small" onChange={urlChange} />
      </Grid>
      <Grid item={true} xs={4} justify="center">
        <Button className="import" variant="contained" onClick={importHandler}>インポート</Button>
      </Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <Box component={Grid} item={true} xs={12} sx={{ display: (imported) ? "block" : "none" }}>
        <Typography variant="h6" color="text.secondary" align="center">
          データの取り込みに成功したウホ！
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          下記のデータで問題なければ、1番下の「公開」ボタンを押すウホ〜
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          間違いがある場合はスプレッドシートを修正して再インポートするウホ〜
        </Typography>
        <Grid item={true} xs={12}>&nbsp;</Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">レア度</TableCell>
                <TableCell align="center">星</TableCell>
                <TableCell align="center">討伐</TableCell>
                <TableCell align="center">かけら</TableCell>
                <TableCell align="center">率</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                imported?.total.items.map(s =>
                  <TableRow key={s._id}>
                    <TableCell component="th" scope="row" align="center">{s.rarity}</TableCell>
                    <TableCell align="center">{s.star}</TableCell>
                    <TableCell align="center">{s.attempts}</TableCell>
                    <TableCell align="center">{s.drops}</TableCell>
                    <TableCell align="center">{s.percent.toFixed(2)}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>

        {
          imported?.monsterDrops.map(d =>
            <Grid item={true} xs={12} key={d._id}>
              <Grid className="monster-star" item={true} xs={12}>
                <Divider textAlign="center">
                  {
                    [...Array(d.star - 5)].map((e, i) =>
                      <img key={i} src="/images/purple-star.webp" alt="purple-star" />
                    )
                  }
                </Divider>
              </Grid>
              <Grid className="breakable-drop" item={true} xs={12}>
                <img src={`${process.env.REACT_APP_GS_PUBLIC_URL}/${process.env.REACT_APP_GS_BUCKET_MONSTER_IMAGE}/${d.imageFile}`} alt={d.name} />
                <Grid item={true} xs={12}>&nbsp;</Grid>
              </Grid>
            </Grid>
          )
        }

        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12} align="center">
          <Button className="publish" variant="contained" onClick={publishHandler}>公開</Button>
        </Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>
      </Box>

      <BottomNavPage admin={true} adminToken={props.adminToken} />
    </Grid>
  );
}
