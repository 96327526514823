// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Typography,
  Box,
  Tabs, Tab,
} from '@mui/material';

// import contexts

// import custom components
import DmgTypeSelect from './DmgTypeSelect';
import DmgTypeInput from './DmgTypeInput';

// import custom CSS
import '../../css/tool.css';

// import custom svgs

export default function DmgCalculator(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [calcType, setCalcType] = React.useState("select");

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */

  /**
   *  Custome variables
   */

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.dmgCalculator")}</Typography>
      </Grid>

      <Grid className="calculator-wrapper" container={true}>
        {/** ツール切り替え */}
        <Box className="calc-type-select">
          <Tabs value={calcType} centered={true} onChange={(e, item) => setCalcType(item)}>
            <Tab value="select" label={t("labels.calcTypeSelect")} />
            <Tab value="input" label={t("labels.calcTypeInput")} />
          </Tabs>
        </Box>
        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>

        {
          calcType === "select" ?
            <DmgTypeSelect setIsLoading={props.setIsLoading} />
            :
            <DmgTypeInput setIsLoading={props.setIsLoading} />
        }

      </Grid>
    </Grid>
  );
}