// import React libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import 'moment/locale/ja';
import {
  Grid,
  Stack,
  Select, MenuItem,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Typography,
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components

// import custom CSS
import '../../css/top.css';

export default function HitPoint(props) {
  /**
   *  React variables
   */
  const [monsters, setMonsters] = React.useState([]);
  const [star, setStar] = React.useState(8);
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonsters();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getMonsters = async () => {
    const res = await axios.get(`/monsters?details=true`);
    setMonsters(res.data);
  };

  const selectStar = (e) => {
    setStar(e.target.value);
  };

  const clickMonsterRow = (monsterName) => {
    navigate(`/monster/${monsterName}`);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    }
  }));

  /**
   *  Custom variables
   */
  const nf = new Intl.NumberFormat('en-US');

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.rareDropDataByPart")}</Typography>
      </Grid>

      <Grid className="monster-hp-wrapper" container={true} justify="center">
        <Grid className="star-selector" item={true} xs={12}>
          <Select value={star} onChange={selectStar}>
            <MenuItem value={8}>
              {[...Array(3)].map((e, i) =>
                <img className="purple-star" key={i} src="/images/purple-star.webp" alt="purple-star" />
              )}
            </MenuItem>
            <MenuItem value={9}>
              {[...Array(4)].map((e, i) =>
                <img className="purple-star" key={i} src="/images/purple-star.webp" alt="purple-star" />
              )}
            </MenuItem>
            <MenuItem value={10}>
              {[...Array(5)].map((e, i) =>
                <img className="purple-star" key={i} src="/images/purple-star.webp" alt="purple-star" />
              )}
            </MenuItem>
          </Select>
        </Grid>

        <Grid item={true} xs={12} alignItems="center" justifyContent="center">
          <Paper className="hitpoint-paper">
            <TableContainer className="hitpoint-table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">{t("labels.hp")}</TableCell>
                    <TableCell align="center">{t("labels.part")}</TableCell>
                    <TableCell align="center">{t("labels.resistance")}</TableCell>
                    <TableCell align="center">{t("labels.percent")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    monsters?.map(m => {
                      const spec = m.specs.find(s => s.star === star);
                      const parts = spec?.parts.filter(s => s.rareDrop === true);
                      return (
                        spec ?
                          <StyledTableRow onClick={() => clickMonsterRow(m.name)} key={m._id}>
                            <TableCell component="th" scope="row" align="center">
                              <img className="monsterIcon" src={`/images/${m.imageFile}`} alt={m.name} />
                            </TableCell>
                            <TableCell align="center">{nf.format(spec.hp)}</TableCell>
                            <TableCell align="center">
                              <Stack direction="column">
                                {
                                  parts.map((p, i) =>
                                    <Typography variant="body2" key={i}>
                                      {t(`parts.${p.name}`)}
                                    </Typography>
                                  )
                                }
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Stack direction="column">
                                {
                                  parts.map((p, i) =>
                                    <Typography variant="body2" key={i}>
                                      {nf.format(p.hp)}
                                    </Typography>
                                  )
                                }
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Stack direction="column">
                                {
                                  parts.map((p, i) =>
                                    <Typography variant="body2" key={i}>
                                      {`${(p.hp / spec.hp * 100).toFixed(0)}%`}
                                    </Typography>
                                  )
                                }
                              </Stack>
                            </TableCell>
                          </StyledTableRow>
                          :
                          null
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item={true} xs={12} textAlign="center">
          <Typography variant="body2" color="text.primary">
            {t("msg.dataMarginError")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}