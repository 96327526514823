// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Typography, Stack, Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// import custom components

export default function SkillPercent(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const { data, filter } = props;

  /**
   *  Custome variables
   */
  const PARAM_TYPE = ["attack", "defense", "affinity"];
  const STONE_TYPE_COUNT = {};

  /**
   *  Custom functions
   */
  const calculateSkill = (skillName, stoneType) => {
    let targetCount = data.records.filter(r => r.skill.name === skillName).length;
    let typeCount = STONE_TYPE_COUNT[stoneType]?.count;

    if (!stoneType) {
      if (filter.options.stoneType === "all") {
        typeCount = data.records.length;
      } else {
        targetCount = data.records.filter(r => r.skill.name === skillName && r.stoneType === filter.options.stoneType).length;
        typeCount = data.records.filter(r => r.stoneType === filter.options.stoneType).length;
      }
    } else if (!typeCount) {
      typeCount = data.records.filter(r => r.stoneType === stoneType).length;
      STONE_TYPE_COUNT[stoneType] = { count: typeCount };
    }

    const percentage = (targetCount / typeCount * 100).toFixed(1);

    return { targetCount, typeCount, percentage: isNaN(percentage) ? (0).toFixed(1) : percentage };
  };

  const calculateParam = (skillName, stoneType, paramType) => {
    let targetList = data.records.filter(r => r.skill.name === skillName && r.param.type === paramType);

    if (!stoneType && filter.options.stoneType !== "all") {
      targetList = targetList.filter(r => r.stoneType === filter.options.stoneType);
    }

    const maxVal = targetList.length === 0 ? 0 : Math.max.apply(Math, targetList.map(t => t.param.value));
    const minVal = targetList.length === 0 ? 0 : Math.min.apply(Math, targetList.map(t => t.param.value));

    return { minVal, maxVal };
  };

  const formatSkillData = (_data) => {
    const element = <>
      <span style={{ fontWeight: 700, fontSize: 16 }}>{_data.percentage}</span>%
      <span style={{ color: "gray" }}>{` (${_data.targetCount}/${_data.typeCount})`}</span>
    </>;

    return element;
  };

  const formatParamData = (_data) => {
    const element = <>
      <span style={{ fontWeight: 700, fontSize: 16 }}>{_data.minVal}</span>&#12316;
      <span style={{ fontWeight: 700, fontSize: 16 }}>{_data.maxVal}</span>
    </>;

    return element;
  };

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  /**
   *  Render the component
   */
  return (
    <TableContainer className="driftstone-table" component={Paper}>
      <Table stickyHeader size="small">
        <TableHead>
          <StyledTableHeadRow>
            <TableCell sx={{ position: "sticky", zIndex: 100, left: 0 }}>
              <Typography variant="caption">
                {t("labels.skill")}
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Typography variant="caption">
                {t("labels.paramRange")}
              </Typography>
            </TableCell>
          </StyledTableHeadRow>
        </TableHead>
        <TableBody>
          {
            data.skills
              .filter(s => filter.options.stoneType === "all" || !s.stoneType || s.stoneType === filter.options.stoneType)
              .map((s, i) =>
                <TableRow key={s._id}>
                  <TableCell sx={{ backgroundColor: i % 2 === 0 ? "white" : "primary.light" }}>
                    <Stack direction="column" justifyContent="center" alignItems="flex-start">
                      <Typography variant="caption" color={`driftstone.${s.stoneType}`}>
                        {s.skill.rare && <>&#9733;</>}
                        {t(`skills.${s.skill.name}`)}
                      </Typography>
                      <Typography variant="caption">
                        {formatSkillData(calculateSkill(s.skill.name, s.stoneType))}
                      </Typography>
                    </Stack>
                  </TableCell>
                  {
                    PARAM_TYPE.map(p =>
                      <TableCell align="center" key={p} sx={{ backgroundColor: i % 2 === 0 ? "white" : "primary.light" }}>
                        <Stack direction="column" justifyContent="center" alignItems="flex-start">
                          <Typography variant="caption">
                            {t(`labels.${p}`)}
                          </Typography>
                          <Typography variant="caption">
                            {formatParamData(calculateParam(s.skill.name, s.stoneType, p))}
                          </Typography>
                        </Stack>
                      </TableCell>
                    )
                  }
                </TableRow>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}