import { useMemo } from "react";

import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import { Document } from "@tiptap/extension-document";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Highlight } from "@tiptap/extension-highlight";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import { Text } from "@tiptap/extension-text";
import { TextAlign } from "@tiptap/extension-text-align";
import { TextStyle } from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";

import {
  HeadingWithAnchor,
  FontSize,
  LinkBubbleMenuHandler,
  TableImproved,
} from "mui-tiptap";

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

export default function useMuiTipTapExtension({ placeholder }) {
  return useMemo(() => {
    return [
      Paragraph,
      FontSize,

      Bold,
      Italic,
      Underline,
      Strike,
      Blockquote,
      HorizontalRule,
      LinkBubbleMenuHandler,
      CustomLinkExtension.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      TableHeader,
      TableRow,
      TableCell,
      TableImproved.configure({
        resizable: true,
      }),

      Color,
      Highlight.configure({ multicolor: true }),


      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),

      OrderedList,
      BulletList,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),

      // Extensions
      Document,
      Gapcursor,
      HardBreak,
      Text,
      TextStyle,
      ListItem,
      HeadingWithAnchor,
      Placeholder.configure({
        placeholder,
      }),
      History,
    ];
  }, [placeholder]);
}