// import React libraries
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute(props) {
  if (props.adminToken === process.env.REACT_APP_ADMIN_TOKEN) {
    return <Outlet />
  }

  return <Navigate to="/" />;
}