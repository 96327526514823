// import third-party libraries
import Moment from 'moment-timezone';
import 'moment/locale/ja';
import {
  Typography,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { RichTextReadOnly } from "mui-tiptap";

// import custom components
import useMuiTipTapExtension from '../../extension/useMuiTipTapExtension';

export default function ArticleCard(props) {
  /**
   *  Thrid-party variables
   */
  const muiTipTapExtension = useMuiTipTapExtension({
    placeholder: "お知らせ本文をロード...",
  });

  /**
   *  Render the component
   */
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {props.article?.title}
        </Typography>
        <Divider />
        <Typography variant="body2" component="div" >&nbsp;</Typography>

        <Typography sx={{ fontSize: 12 }} color="text.disabled" gutterBottom>
          {Moment().format('YYYY/MM/DD HH:mm')}
        </Typography>

        <RichTextReadOnly content={props.article?.content} extensions={muiTipTapExtension} />

      </CardContent>
    </Card>
  );
}