// import React libraries
import React from 'react';
import { Link } from 'react-router-dom';

// import third-party libraries
import Moment from 'moment-timezone';
import 'moment/locale/ja';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Badge
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import BottomNavPage from '../common/BottomNav';

export default function Notification(props) {
  /**
   *  React variables
   */
  const [notifications, setNotifications] = React.useState([]);
  const today = Moment();

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getArticles();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getArticles = async () => {
    const params = { limit: process.env.REACT_APP_NOTIFICATION_LIMIT }
    const res = await axios.get(`/articles`, { params });

    setNotifications(res.data);
  };

  const isNew = (ts) => {
    return today.diff(Moment(ts), "days") > process.env.REACT_APP_NOTIFICATION_DURATION;
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">お知らせ一覧</Typography>
      </Grid>

      {
        notifications?.filter(n => n.pinned).map(n =>
          <Card className="notification-item" variant="outlined" key={n._id}>
            <CardActionArea component={Link} to={`/notification/${n.id}`}>
              <CardContent>
                <Grid container={true} direction="row" wrap="nowrap">
                  <Grid className="notification-status" item={true}>
                    {
                      isNew(n.updatedAt) ?
                        <PushPinIcon color="primary" />
                        :
                        <Badge variant="dot" color="error">
                          <PushPinIcon color="primary" />
                        </Badge>
                    }
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="body2" gutterBottom>
                      {n.title}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.disabled">
                      {Moment(n.updatedAt).format('YYYY/MM/DD HH:mm')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        )
      }
      {
        notifications?.filter(n => !n.pinned).map(n =>
          <Card className="notification-item" variant="outlined" key={n._id}>
            <CardActionArea component={Link} to={`/notification/${n.id}`}>
              <CardContent>
                <Grid container={true} direction="row" wrap="nowrap">
                  <Grid className="notification-status" item={true}>
                    {
                      isNew(n.updatedAt) ?
                        <ArticleOutlinedIcon color="primary" />
                        :
                        <Badge variant="dot" color="error">
                          <ArticleIcon color="primary" />
                        </Badge>
                    }
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="body2" gutterBottom>
                      {n.title}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.disabled">
                      {Moment(n.updatedAt).format('YYYY/MM/DD HH:mm')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        )
      }

      <BottomNavPage />
    </Grid>
  );
}