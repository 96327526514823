// import React libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import Moment from 'moment-timezone';
import 'moment/locale/ja';
import {
  Grid,
  Tabs, Tab,
  Box,
  Card, CardContent,
  Typography,
  Divider
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import InArticleAd from '../ads/InArticle';

// import custom CSS
import '../../css/top.css';

export default function RareDrop(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [monsters, setMonsters] = React.useState([]);
  const [totals, setTotals] = React.useState({});
  const [totalByItems, setTotalByItems] = React.useState([]);
  const [dropMenu, setDropMenu] = React.useState("all");

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonstersTotalDrop();
      await getMonsters();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */

  const getMonstersTotalDrop = async () => {
    const res = await axios.get(`/totals`);
    const totals = res.data?.items?.reduce((accumulator, currentValue) => {
      const index = accumulator.findIndex(a => a[0].name === currentValue.name);

      if (index === -1) {
        accumulator.push([currentValue]);
      } else {
        accumulator[index].push(currentValue);
      }

      return accumulator;
    }, []);

    setTotalByItems(totals);
    setTotals(res.data);
  };

  const getMonsters = async () => {
    const res = await axios.get(`/monsters`);
    setMonsters(res.data);
  };

  const dropMenuHandler = (e, selected) => {
    setDropMenu(selected);
  };

  const getRewardsPercent = (data) => {
    let percent = 0;
    switch (dropMenu) {
      case "basic":
        percent = data.percentNormal;
        break;

      case "group":
        percent = data.percentGroup;
        break;

      default:
        percent = data.percent;
        break;
    }

    return percent;
  };

  const getRewardsAttempts = (data) => {
    let attempts = 0;
    switch (dropMenu) {
      case "basic":
        attempts = data.attempts;
        break;

      case "group":
        attempts = data.groupAttempts;
        break;

      default:
        attempts = data.attempts;
        break;
    }

    return attempts;
  };

  const AnimateNumber = (props) => {
    const { number } = useSpring({
      from: { number: 0.00 },
      number: props.finalNumber ? props.finalNumber : 0.00,
      delay: 300,
      config: { mass: 1, tension: 20, friction: 1.5 * props.finalNumber }
    });
    return <animated.div className="animatedDiv">{number.to((n) => n.toFixed(2))}</animated.div>;
  };

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.rareDropRate")}</Typography>
      </Grid>

      <Box className="drop-item-menu">
        <Tabs value={dropMenu} centered={true} onChange={dropMenuHandler}>
          <Tab value="all" label={t("labels.allRewards")} />
          <Tab value="basic" label={t("labels.basicRewards")} />
          <Tab value="group" label={t("labels.groupRewards")} />
        </Tabs>
      </Box>

      {
        totalByItems?.map((total) =>
          <Grid className="drop-item-container" container={true} justify="center" key={total[0]._id}>
            <Grid className="drop-item" item={true} xs={12}>
              <Divider textAlign="center">
                <img src={`/images/${total[0].name}.webp`} alt={total[0].name} />
              </Divider>
            </Grid>
            {
              total.map((data) =>
                <Grid className="drop-data-container" item={true} xs={6} key={data._id}>
                  <Card className="drop-data">
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {[...Array(data.star - 5)].map((e, i) =>
                          <img className="purple-star" key={i} src="/images/purple-star.webp" alt="purple-star" />
                        )}
                      </Typography>
                      <Typography className="drop-percent" variant="h5" component="div" align="center">
                        <AnimateNumber finalNumber={getRewardsPercent(data)} />
                        <Box fontSize="body1.fontSize" display="inline">%</Box>
                      </Typography>

                      <Typography variant="body2" color="text.secondary" component="div" align="right">
                        {`${t("labels.defeatCount")}:`}
                        <Box fontWeight="bold" display="inline">
                          {getRewardsAttempts(data)}
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
            <Grid item={true} xs={12}>&nbsp;</Grid>
          </Grid>
        )
      }

      <Grid item={true} xs={12}>
        <Typography variant="body2" color="text.secondary" align="center">
          {`${t("labels.lastUpdate")}: ${Moment(totals.createdAt).locale('ja').format('LLL')}`}
        </Typography>
      </Grid>

      <InArticleAd dataAdSlot="6172942903" />
      <Grid item={true} xs={12}>&nbsp;</Grid>
      <Grid item={true} xs={12}>&nbsp;</Grid>

      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.rareDropRateByMonster")}</Typography>
      </Grid>

      <Grid className="monster-container-wrapper" container={true} justify="center">
        {
          monsters?.map((m, i) =>
            <Grid className="monster-container" key={i} item={true} xs={2}>
              <Link to={`/monster/${m.name}`}>
                <img className="monsterIcon" src={`/images/${m.imageFile}`} alt={m.name} />
              </Link>
            </Grid>
          )
        }
      </Grid>
    </>
  );
}