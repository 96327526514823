// import React libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { CssBaseline, Grid, Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom CSS
import '../../css/header.css';

export default function Header() {
    /**
     *  React variables
     */
    const axios = React.useContext(AxiosContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [monsters, setMonsters] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const menuAnchor = React.useRef(null);

    /**
     *  React Effects
     */
    React.useEffect(() => {
        getMonsters();
    }, []);

    const getMonsters = async () => {
        const res = await axios.get(`/monsters`);
        setMonsters(res.data);
    };

    /**
     *  Custom functions
     */
    const menuOpenHandler = () => {
        setOpen(!open);
    };

    const menuCloseHandler = (monster) => {
        setOpen(!open);
        if (monster?.name) {
            navigate(`/monster/${monster.name}`);
        } else if (monster === "home") {
            navigate("/");
        }
    };

    /**
     *  Render the component
     */
    return (
        <Grid container={true} justify="center">
            <CssBaseline />
            <Button className="menuButton" ref={menuAnchor} variant="text" onClick={menuOpenHandler} startIcon={<KeyboardArrowDownIcon />}>
                {t("menu.header.selectMonster")}
            </Button>
            <Menu
                anchorEl={menuAnchor.current}
                open={open}
                onClose={menuCloseHandler}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                elevation={0}
                slotProps={{ paper: { className: "menuList" } }}
            >
                {
                    monsters?.map((m, i) =>
                        <MenuItem onClick={() => menuCloseHandler(m)} key={m._id}>
                            <img className="menuIcon" src={`/images/${m.imageFile}`} alt={m.name} />{t(`monsters.${m.name}.name`)}
                        </MenuItem>
                    )
                }
            </Menu>
        </Grid>
    );
}