// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import {
  Button,
  Collapse,
  Table, TableBody, TableRow, TableCell,
  Stack,
  Alert, Badge
} from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SwipeIcon from '@mui/icons-material/Swipe';

// import contexts

// import custom components

// import custom CSS

export default function SubMotion(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const [collapse, setCollapse] = React.useState(true);
  const { row, bgColor, showMemo } = props;

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */

  /**
   *  Custom functions
   */
  const getTotalMotionValue = (row) => {
    if (row.type === "fixed") {
      return "N/A";
    } else if (row.motionValue) {
      return row.motionValue;
    }
    const total = row.subMove.reduce((totalMotionValue, { motionValue, maxHit }) =>
      totalMotionValue + motionValue * maxHit,
      0
    );

    return isNaN(total) ? "-" : total;
  };

  const getMotionAttribute = (row) => {
    if (row.subMove.length === 0) {
      return t(`labels.${row.attribute}`);
    }

    const key = row.subMove[0].attribute;
    const consistent = row.subMove.every(s => s.attribute === key);

    return consistent ? t(`labels.${key}`) : t("labels.mixedAttr");
  }

  const CollapsedRow = styled(TableRow)(({ theme }) => ({
    "&:not(:last-child) th, &:not(:last-child) td": {
      border: 0,
    }
  }));

  /**
   *  Custome variables
   */

  /**
   *  Render the component
   */
  return (
    <>
      <TableRow key={row._id} sx={{ backgroundColor: bgColor }}>
        <TableCell align="center" sx={{ display: { xs: "none", md: "table-cell" } }}>
          {t(`labels.${row.weapon}`)}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          <Stack direction="column">
            {row.move}
            {
              row.subMove.length > 0 ?
                <Button variant="text" size="small" sx={{ width: "100px" }} onClick={() => setCollapse(!collapse)}>
                  {t(collapse ? `labels.openDetails` : `labels.closeDetails`)}
                </Button>
                :
                <></>
            }
            {
              showMemo && row.memo ?
                <Alert severity="info" color="warning">{row.memo}</Alert>
                :
                <></>
            }
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ display: { xs: "none", md: "table-cell" } }}>
          {getMotionAttribute(row)}
        </TableCell>
        <TableCell align="right">
          {
            getTotalMotionValue(row)
          }
        </TableCell>
        <TableCell align="right">{row.maxHit}</TableCell>
      </TableRow>
      {
        row.subMove.length > 0 ?
          <TableRow>
            <TableCell sx={{ padding: 0, border: 0 }} colSpan={6}>
              <Collapse in={!collapse} timeout="auto" unmountOnExit >
                <Table>
                  <TableBody>
                    {
                      row.subMove?.map((sub, i) =>
                        <CollapsedRow key={sub._id}>
                          <TableCell sx={{ width: 35 }} />
                          <TableCell align="center" sx={{ width: 60, display: { xs: "none", md: "table-cell" } }}>
                            {t(`labels.${row.weapon}`)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            <Stack direction="column">
                              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                {sub.name}
                                {
                                  sub.swipe &&
                                  <Badge badgeContent={sub.swipe} color="primary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <SwipeIcon color="action" />
                                  </Badge>
                                }
                                {
                                  sub.tap &&
                                  <Badge badgeContent={sub.tap} color="primary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                    <TouchAppIcon color="action" />
                                  </Badge>
                                }
                              </Stack>
                              {
                                sub.memo ?
                                  <Alert severity="info" color="warning">{sub.memo}</Alert>
                                  :
                                  <></>
                              }
                            </Stack>
                          </TableCell>
                          <TableCell align="center" sx={{ width: 50, display: { xs: "none", md: "table-cell" } }}>
                            {t(`labels.${sub.attribute}`)}
                          </TableCell>
                          <TableCell align="right" sx={{ width: 50 }}>{sub.motionValue ?? "-"}</TableCell>
                          <TableCell align="right" sx={{ width: 50 }}>{sub.maxHit}</TableCell>
                        </CollapsedRow>
                      )
                    }
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
          :
          <></>
      }
    </>
  );
}