// import React libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

// import third-party libraries
import {
  Grid,
  Typography,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import BottomNav from '../common/BottomNav';

// import custom CSS
import '../../css/article.css';

export default function ArticleList(props) {
  /**
   *  React variables
   */
  const navigate = useNavigate();
  const [articles, setArticles] = React.useState([]);

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getArticles();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getArticles = async () => {
    const params = { all: true }
    const res = await axios.get(`/articles`, { params });

    if (res.data) {
      setArticles(res.data);
    }
  };

  const publishHandler = async (article) => {
    const confirm = window.confirm(`お知らせ#${article.id}を公開するウホ？`);
    if (confirm) {
      props.setIsLoading(true);
      article.display = true;
      await axios.put(`/article/${article._id}`, article);
      props.setIsLoading(false);
    }
  };

  const hideHandler = async (article) => {
    const confirm = window.confirm(`お知らせ#${article.id}を非公開にするウホ？`);
    if (confirm) {
      props.setIsLoading(true);
      article.display = false;
      await axios.put(`/article/${article._id}`, article);
      props.setIsLoading(false);
    }
  };

  const pinHandler = async (article) => {
    const confirm = window.confirm(`お知らせ#${article.id}のピン留めするウホ？`);
    if (confirm) {
      props.setIsLoading(true);
      article.pinned = true;
      await axios.put(`/article/${article._id}`, article);
      props.setIsLoading(false);
    }
  };

  const unPinHandler = async (article) => {
    const confirm = window.confirm(`お知らせ#${article.id}のピン留めを外すウホ？`);
    if (confirm) {
      props.setIsLoading(true);
      article.pinned = false;
      await axios.put(`/article/${article._id}`, article);
      props.setIsLoading(false);
    }
  };

  const editHandler = async (article) => {
    navigate("/admin/createArticle", { state: { adminToken: props.adminToken, article } });
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">お知らせ一覧</Typography>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width={30}>公開</TableCell>
              <TableCell align="center" width={30}>ピン</TableCell>
              <TableCell align="center" width={30}>編集</TableCell>
              <TableCell>タイトル</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              articles?.map(article =>
                <TableRow key={article._id}>
                  <TableCell component="th" scope="row" align="center">
                    {
                      article.display ?
                        <VisibilityIcon color="primary" onClick={() => { hideHandler(article) }} />
                        :
                        <VisibilityOffOutlinedIcon onClick={() => { publishHandler(article) }} />
                    }
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {
                      article.pinned ?
                        <PushPinIcon color="primary" onClick={() => { unPinHandler(article) }} />
                        :
                        <PushPinOutlinedIcon onClick={() => { pinHandler(article) }} />
                    }
                  </TableCell>
                  <TableCell align="center">
                    <ModeEditIcon onClick={() => { editHandler(article) }} />
                  </TableCell>
                  <TableCell>{article.title}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <BottomNav admin={true} adminToken={props.adminToken} />
    </Grid>
  );
}