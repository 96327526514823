// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { Grid, Typography, Divider, Stack } from '@mui/material';

export default function DropData(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const { monster } = props;

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.rareDropForBasicReward")}</Typography>
      </Grid>

      {
        monster?.map((m) =>
          <Grid className="monster-drop-wrapper" container={true} justify="center" key={m._id}>
            <Grid className="monster-star" item={true} xs={12}>
              <Divider textAlign="center">
                {
                  [...Array(m.star - 5)].map((e, i) =>
                    <img key={i} src="/images/purple-star.webp" alt="purple-star" />
                  )
                }
              </Divider>
            </Grid>

            {m.normalDrop?.map((normal) =>
              <Grid className="normal-drop" item={true} xs={3} key={normal._id}>
                <Grid className="percent-box" item={true} xs={12}>
                  <Typography className="percent-number" variant="h6">{normal.percent.toFixed(2)}</Typography>
                  <Typography className="percent-label" variant="body2">%</Typography>
                </Grid>
                <Grid className="attempts-box" item={true} xs={12}>
                  <Typography className="attempts-try" variant="body1">{normal.drops}</Typography>
                  <Typography className="attempts-total" variant="body2">/{normal.attempts}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Typography variant="body2">{t("labels.groupRewards")}</Typography>
              </Stack>
            </Grid>

            {
              m.groupDrop?.map(group =>
                group.attempts === 0 ?
                  <React.Fragment key={group._id}></React.Fragment>
                  :
                  <Grid className="normal-drop" item={true} xs={3} key={group._id}>
                    <Grid className="percent-box" item={true} xs={12}>
                      <Typography className="percent-number" variant="h6">{group.percent.toFixed(2)}</Typography>
                      <Typography className="percent-label" variant="body2">%</Typography>
                    </Grid>
                    <Grid className="attempts-box" item={true} xs={12}>
                      <Typography className="attempts-try" variant="body1">{group.drops}</Typography>
                      <Typography className="attempts-total" variant="body2">/{group.attempts}</Typography>
                    </Grid>
                  </Grid>
              )
            }
          </Grid>
        )
      }
    </>
  );
}