import { createTheme } from '@mui/material/styles';

/**
 * Define theme
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#c0b08e",
      light: "#f9f7f4",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#c0b08e",
    },
    background: {
      default: "#ffffff",
    },
    text: { primary: "#564a30" },
    driftstone: {
      crimson: "lightcoral",
      azure: "royalblue",
      amber: "goldenrod",
      cyan: "lightskyblue",
      pale: "lightslategray"
    }
  },
});

export default theme;