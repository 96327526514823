// import React libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// import third-party libraries
import { ThemeProvider } from '@mui/material/styles';

// import custom components
import ErrorBoundary from './extension/ErrorBoundary';
import MaintenancePage from './component/common/Maintenance';
import App from './component/App';
import Footer from './component/common/Footer';

// import custom css
import './index.css';

// import custom config
import theme from './theme/theme.js'
import './i18n/config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        {
          process.env.REACT_APP_MAINTENANCE_MODE === "true" ?
            <MaintenancePage />
            :
            <App />
        }
        <Footer />
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
