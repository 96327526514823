// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import 'moment/locale/ja';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Paper,
  Fab,
  SvgIcon,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Typography,
} from '@mui/material';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import SubMotion from './SubMotion';

// import custom CSS
import '../../css/motion.css';

// import custom svgs
import { ReactComponent as SnsIcon } from '../../svg/weapons/sns.svg'
import { ReactComponent as GsIcon } from '../../svg/weapons/gs.svg'
import { ReactComponent as LsIcon } from '../../svg/weapons/ls.svg'
import { ReactComponent as HammerIcon } from '../../svg/weapons/hammer.svg'
import { ReactComponent as LbIcon } from '../../svg/weapons/lb.svg'
import { ReactComponent as BowIcon } from '../../svg/weapons/bow.svg'
import { ReactComponent as LanceIcon } from '../../svg/weapons/lance.svg'
import { ReactComponent as DbIcon } from '../../svg/weapons/db.svg'
import { ReactComponent as CbIcon } from '../../svg/weapons/cb.svg'
import { ReactComponent as GlIcon } from '../../svg/weapons/gl.svg'
import { ReactComponent as HbIcon } from '../../svg/weapons/hb.svg'

export default function Motion(props) {
  /**
   *  React variables
   */
  const [motions, setMotions] = React.useState([]);
  const { t } = useTranslation();
  const [weapon, setWeapon] = React.useState("sns");

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMotions();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getMotions = async () => {
    const res = await axios.get(`/motions`);
    setMotions(res.data);
  };

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  /**
   *  Custome variables
   */
  const weaponList = [
    { name: "sns", icon: SnsIcon },
    { name: "db", icon: DbIcon },
    { name: "gs", icon: GsIcon },
    { name: "ls", icon: LsIcon },
    { name: "hammer", icon: HammerIcon },
    { name: "lance", icon: LanceIcon },
    { name: "gl", icon: GlIcon },
    { name: "cb", icon: CbIcon },
    { name: "lb", icon: LbIcon },
    { name: "hb", icon: HbIcon },
    { name: "bow", icon: BowIcon }
  ];

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.motionList")}</Typography>
      </Grid>

      <Grid className="motion-list-wrapper" container={true}>
        <Grid className="weapon-select-container" container={true}>
          <Stack className="weapon-select" direction="row" spacing={2}>
            {
              weaponList.map(w =>
                <Fab size="small" color={weapon === w.name ? "primary" : "secondary"} onClick={() => setWeapon(w.name)} key={w.name}>
                  <SvgIcon component={w.icon} inheritViewBox />
                </Fab>
              )
            }
          </Stack>
        </Grid>

        <Grid item={true} xs={12} alignItems="center" justifyContent="center">
          <TableContainer className="motion-table" component={Paper}>
            <Table>
              <TableHead>
                <StyledTableHeadRow>
                  <TableCell align="center" sx={{ width: 120, display: { xs: "none", md: "table-cell" } }}>
                    {t("labels.weapon")}
                  </TableCell>
                  <TableCell align="left">
                    {t("labels.move")}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 50, display: { xs: "none", md: "table-cell" } }}>
                    {t("labels.weaponAttr")}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 50 }}>
                    {t("labels.motionValue")}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 50 }}>
                    {t("labels.maxHit")}
                  </TableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {
                  motions?.map((m, i) =>
                    weapon === m.weapon ?
                      <SubMotion key={m._id} row={m} bgColor={i % 2 === 0 ? "" : "primary.light"} showMemo={props.showMemo} />
                      :
                      <TableRow key={m._id}></TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid >
    </>
  );
}