// import React libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Card, CardContent,
  Divider,
  List, ListItem,
  Paper,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Button,
  Typography,
  Alert,
  Link,
  FormControl, InputLabel, Select, MenuItem,
  TextField
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

// import custom components
import MonsterStatus from './MonsterStatus';

// import custom CSS

export default function Blast(props) {
  /**
   *  React variables
   */
  const { t } = useTranslation();
  const statusFormulaRef = React.useRef(null);
  const monsterStatusRef = React.useRef(null);
  const statusEnhanceRef = React.useRef(null);
  const statusOverrideRef = React.useRef(null);
  const statusCalculatorRef = React.useRef(null);

  const eAttackRef = React.useRef(0);
  const [weapon, setWeapon] = React.useState("sns");
  const thresholdRef = React.useRef(0);
  const [statusCount, setStatusCount] = React.useState(1);
  const [statusCalcResult, setStatusCalcResult] = React.useState(0);

  /**
   *  Thrid-party variables
   */

  /**
   *  React Effects
   */
  React.useEffect(() => {
    calculate();
  }, [weapon, statusCount]);

  /**
   *  Custom functions
   */
  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }));

  const calculate = () => {
    const threshold = thresholdRef.current.value * (1.3 ** (statusCount - 1));
    const eattack = eAttackRef.current.value * WEAPON_MODIFIER.find(w => w.weapon === weapon).modifier;

    if (!isNaN(threshold) && !isNaN(eattack) && eattack !== 0) {
      setStatusCalcResult(Math.ceil(threshold / eattack));
    }
  };

  /**
   *  Custome variables
   */
  const AGENDA = [
    { label: "statusFormula", ref: statusFormulaRef },
    { label: "monsterStatusAndDamage", ref: monsterStatusRef },
    { label: "statusEnhance", ref: statusEnhanceRef },
    { label: "statusOverride", ref: statusOverrideRef },
    { label: "statusCalculator", ref: statusCalculatorRef }
  ];
  const WEAPON_MODIFIER = [
    { weapon: "sns", modifier: 1 },
    { weapon: "db", modifier: 0.4 },
    { weapon: "gs", modifier: 2 },
    { weapon: "ls", modifier: 1.4 },
    { weapon: "hammer", modifier: 1.6 },
    { weapon: "lance", modifier: 1.3 },
    { weapon: "gl", modifier: 0.9 },
    { weapon: "cb", modifier: 1.3 },
    { weapon: "lb", modifier: 0 },
    { weapon: "hb", modifier: 0 },
    { weapon: "bow", modifier: 0.6 },
  ];

  /**
   *  Render the component
   */
  return (
    <>
      <Card>
        <CardContent>
          {
            /**
             * 目次
             */
          }
          <Typography variant="h6">{t("labels.agenda")}</Typography>
          <Stack direction="column" spacing={1}>
            {
              AGENDA.map(a =>
                <Stack direction="row" spacing={0.5} key={a.label}>
                  <img className="status-icon" src="/images/blast.webp" alt="blast" />
                  <Link variant="body2" underline="none" href={`#${a.label}`} onClick={() => a.ref.current.scrollIntoView()}>
                    {t(`menu.header.${a.label}`)}
                  </Link>
                </Stack>
              )
            }
          </Stack>

          {
            /**
             * 蓄積値の計算式
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusFormulaRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[0].label}`)}
            </Typography>
          </Divider>
          <Grid item={true} xs={12}>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ marginBottom: 2, fontWeight: "bold", bgcolor: "primary.light" }}>
              <Typography variant="h6">{t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.blast") })}</Typography>
              <ClearIcon />
              <Typography variant="h6">{t("labels.statusCondition.weaponModifier")}</Typography>
            </Stack>
            <Typography variant="body2">
              {`${t("labels.statusCondition.blast")}${t("labels.statusCondition.formulaIntro")}`}
            </Typography>
            <Typography variant="body2">
              {t("labels.statusCondition.formulaExclude")}
            </Typography>
            <List dense={true}>
              <ListItem>
                <Typography variant="caption">{t("labels.pattackValue")}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption">{`${t("labels.move")}${t("labels.motionValue")}`}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="caption">{t("menu.header.statusDamage")}</Typography>
              </ListItem>
            </List>
            <Typography variant="body2">
              {t("labels.statusCondition.formulaExclude2")}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 2 }}>
              {t("labels.statusCondition.formulaExplain")}
            </Typography>

            <Button variant="text" startIcon={<QuestionMarkIcon />}>
              {t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.blast") })}
            </Button>
            <Typography variant="body2">
              {t("labels.statusCondition.elementValueExplain", { status: t("labels.statusCondition.blast") })}
            </Typography>

            <Button variant="text" startIcon={<QuestionMarkIcon />}>
              {t("labels.statusCondition.weaponModifier")}
            </Button>
            <Typography variant="body2">
              {t("labels.statusCondition.weaponModifierExplain")}
            </Typography>
            <TableContainer component={Paper} sx={{ maxWidth: 250 }}>
              <Table size="small">
                <TableHead>
                  <StyledTableHeadRow>
                    <TableCell align="left">{t("labels.weaponType")}</TableCell>
                    <TableCell align="center">{t("labels.statusCondition.modifier")}</TableCell>
                  </StyledTableHeadRow>
                </TableHead>
                <TableBody>
                  {
                    WEAPON_MODIFIER.map(w =>
                      <TableRow key={w.weapon}>
                        <TableCell align="left">{t((`labels.${w.weapon}`))}</TableCell>
                        <TableCell align="center">{w.modifier === 0 ? "N/A" : w.modifier.toFixed(1)}</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="caption">
              *N/A={t("labels.statusCondition.noWeapon", { status: t("labels.statusCondition.blast") })}
            </Typography>

            <Alert severity="error">
              <Typography variant="body2">{t("labels.statusCondition.formulaOverflow")}</Typography>
              <Typography variant="body2">{t("labels.statusCondition.formulaOverflow2", { status: t("labels.statusCondition.blast") })}</Typography>
            </Alert>

          </Grid>

          {
            /**
             * モンスター別耐性値と与ダメージ
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={monsterStatusRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[1].label}`)}
            </Typography>
          </Divider>

          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {t("labels.statusCondition.monsterIntro", { status: t("labels.statusCondition.blast") })}
          </Typography>
          <Typography variant="body2">
            爆破状態になった通常モンスターは最大HP(<span style={{ color: "red" }}>*1</span>)の5%、古龍(<span style={{ color: "red" }}>*2</span>)は3%に相当する爆発ダメージを受けます。
          </Typography>
          <Typography variant="body2">
            ダメージ割合は固定で、武器グレードやスキルによって増加はしません。
          </Typography>

          <Stack direction="column" sx={{ marginTop: 1 }}>
            <Typography variant="caption">
              <span style={{ color: "red" }}>*1</span>)毒ダメージと異なり、グループハントの場合はマルチHPに対して5%のダメージを与えます。
            </Typography>
            <Typography variant="caption">
              <span style={{ color: "red" }}>*2</span>)クシャルダオラとテオ・テスカトルへの爆発ダメージは最大HPの3%。
            </Typography>
          </Stack>

          <MonsterStatus monsters={props.monsters} statusType={props.statusType} />

          {
            /**
             * 耐性値の増加
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusEnhanceRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[2].label}`)}
            </Typography>
          </Divider>

          <Typography variant="body2">
            モンスターは爆破状態になる度に耐性値が上昇し、次の爆破が入りにくくなります。耐性値は<span style={{ color: "blue", fontSize: 18 }}>1.3</span>倍ずつ上昇します。
          </Typography>
          <Alert severity="info">
            <Stack direction="column">
              <Typography variant="body2">爆破耐性値1000の場合</Typography>
              <Typography variant="body2">1回目:1000、2回目:1300、3回目:1690、4回目:2197…となります。</Typography>
            </Stack>
          </Alert>

          {
            /**
             * 状態異常の上書き
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusOverrideRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[3].label}`)}
            </Typography>
          </Divider>
          <Typography variant="body2">
            爆発の仕様上（蓄積値が溜まった瞬間に爆発しリセットされるため）、上書きは起こり得ません。
          </Typography>

          {
            /**
             * 蓄積値計算ツール
             */
          }
          <Divider className="status-agenda-divider" textAlign="center" ref={statusCalculatorRef}>
            <Typography variant="body2" color="text.primary">
              {t(`menu.header.${AGENDA[4].label}`)}
            </Typography>
          </Divider>

          <Grid container={true}>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>{t("labels.weaponType")}</InputLabel>
                <Select value={weapon} label={t("labels.weaponType")} onChange={(event) => setWeapon(event.target.value)}>
                  {
                    WEAPON_MODIFIER.filter(w => w.modifier > 0).map(w =>
                      <MenuItem key={w.weapon} value={w.weapon}>{t((`labels.${w.weapon}`))}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <TextField type="number" label={t("labels.statusCondition.elementValue", { status: t("labels.statusCondition.blast") })}
                onChange={calculate} inputRef={eAttackRef} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <TextField type="number" label={`${t("labels.statusCondition.blast")}${t("labels.statusCondition.threshold")}`}
                onChange={calculate} inputRef={thresholdRef} InputLabelProps={{ shrink: true, }} InputProps={{ inputMode: "numeric" }} />
            </Grid>
            <Grid className="status-calc-input" item={true} xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>{t("labels.statusCondition.statusCount", { status: t("labels.statusCondition.explode") })}</InputLabel>
                <Select value={statusCount} label={t("labels.statusCondition.statusCount", { status: t("labels.statusCondition.blast") })}
                  onChange={(event) => setStatusCount(event.target.value)}>
                  {
                    [...Array(15)].map((e, i) =>
                      <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: 3, marginBottom: 10 }}>
            <Typography variant="h4" sx={{ marginRight: 1 }}>
              {statusCalcResult}
            </Typography>
            <Typography variant="body2">
              回目のエフェクトで爆発します！
            </Typography>
          </Stack>

        </CardContent>
      </Card>
    </>
  );
}