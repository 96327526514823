// import React libraries
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// import third-party libraries
import {
  Grid,
  Typography,
  Stack,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  RichTextEditor,
  LinkBubbleMenu,
  TableBubbleMenu,
} from "mui-tiptap";

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import ArticleCardPage from './AricleCard';
import EditorMenuPage from '../common/EditorMenu';
import BottomNavPage from '../common/BottomNav';
import useMuiTipTapExtension from '../../extension/useMuiTipTapExtension';

// import custom CSS
import '../../css/article.css';

export default function Editor(props) {
  /**
   *  React variables
   */
  const navigate = useNavigate();
  const location = useLocation();
  const draft = localStorage.getItem("draftArticle") ?
    JSON.parse(localStorage.getItem("draftArticle")) :
    { title: "", content: "", pinned: false }
  const [article, setArticle] = React.useState(location.state?.article ?? draft);
  const titleRef = React.useRef(null);
  const rteRef = React.useRef(null);
  const pinRef = React.useRef(false);

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);
  const muiTipTapExtension = useMuiTipTapExtension({
    placeholder: "お知らせ本文を入力...",
  });

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(false);

      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    })();
  }, []);

  /**
   *  Custom functions
   */
  const onBeforeUnload = (e) => {
    if (titleRef.current?.value || rteRef.current?.editor?.getHTML()) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const previewHandler = async () => {
    setArticle({
      ...article,
      title: titleRef.current?.value,
      content: rteRef.current?.editor?.getHTML() ?? "",
      pinned: pinRef.current?.checked
    });
    localStorage.setItem("draftArticle", JSON.stringify(article));
  };

  const publishHandler = async () => {
    props.setIsLoading(true);

    let res = null;
    if (article._id) {
      res = await axios.put(`/article/${article._id}`, article);
    } else {
      res = await axios.post(`/articles`, article);
    }

    props.setIsLoading(false);
    if (res.data?._id) {
      localStorage.removeItem("draftArticle");
      navigate("/admin/listArticle", { state: { adminToken: props.adminToken } });
    } else {
      alert("お知らせの投稿に失敗したウホ...");
    }
  };

  /**
   *  Render the component
   */
  return (
    <Grid container={true} justify="center">
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">お知らせ記事作成</Typography>
      </Grid>

      <Grid className="article-title" item={true} xs={12} align="center">
        <TextField
          className="article-title-input" label="お知らせタイトル(30文字以内)" variant="outlined"
          value={article.title} inputProps={{ maxLength: 30 }} inputRef={titleRef} onChange={previewHandler}
        />
      </Grid>

      <Grid className="article-editor" item={true} xs={12}>
        <RichTextEditor
          ref={rteRef}
          extensions={muiTipTapExtension}
          renderControls={() => <EditorMenuPage />}
          content={article.content}
          onUpdate={previewHandler}

          RichTextFieldProps={{
            footer: (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                {
                  //<Button variant="outlined" size="small" onClick={previewHandler}>プレビュー</Button>
                }

                <FormControlLabel control={
                  <Checkbox inputRef={pinRef} checked={article?.pinned === true} onChange={previewHandler} />
                } label="ピン留め" />
                <Button variant="contained" size="small" onClick={publishHandler}>{article._id ? "保存" : "投稿"}</Button>
              </Stack>
            )
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Grid>

      <Grid item={true} xs={12}>
        <Divider variant="middle" textAlign="center">
          <Stack direction="row" alignItems="center">
            <ArrowDropDownIcon />
            プレビュー
          </Stack>
        </Divider>
      </Grid>

      <Grid className="article-viewer" item={true} xs={12}>
        <ArticleCardPage article={article} />
      </Grid>

      <BottomNavPage admin={true} adminToken={props.adminToken} />
    </Grid>
  );
}