// import React libraries
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import third-party libraries
import {
  Grid,
  Stack,
  Button, Badge,
  Typography,
} from '@mui/material';
import Moment from 'moment-timezone';
import 'moment/locale/ja';

// import contexts
import { AxiosContext } from '../../context/axios';

// import custom components
import PoisonFrag from './Poison';
import ParalysisFrag from './Paralysis';
import SleepFrag from './Sleep';
import BlastFrag from './Blast';
import BottomNav from '../common/BottomNav';

// import custom CSS
import '../../css/status.css';

export default function Status(props) {
  /**
   *  Custome variables
   */
  const STATUS_TYPE_LIST = ["poison", "paralysis", "sleep", "blast"];

  /**
   *  React variables
   */
  const params = useParams();
  const navigate = useNavigate();
  const [monsters, setMonsters] = React.useState([]);
  const [statusType, setStatusType] = React.useState(params.type ?? STATUS_TYPE_LIST[0]);
  const { t } = useTranslation();

  /**
   *  Thrid-party variables
   */
  const axios = React.useContext(AxiosContext);

  /**
   *  React Effects
   */
  React.useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      await getMonsters();
      props.setIsLoading(false);
    })();
  }, []);

  /**
   *  Custom functions
   */
  const getMonsters = async () => {
    const res = await axios.get(`/monsters?details=true`);
    setMonsters(res.data);
  };

  const statusNav = (path) => {
    setStatusType(path);
    navigate(`/status/${path}`);
  };

  const renderFragment = () => {
    let fragment = null;

    switch (statusType) {
      case STATUS_TYPE_LIST[0]:
        fragment = <PoisonFrag monsters={monsters} statusType={statusType} />
        break;

      case STATUS_TYPE_LIST[1]:
        fragment = <ParalysisFrag monsters={monsters} statusType={statusType} />
        break;

      case STATUS_TYPE_LIST[2]:
        fragment = <SleepFrag monsters={monsters} statusType={statusType} />
        break;

      case STATUS_TYPE_LIST[3]:
        fragment = <BlastFrag monsters={monsters} statusType={statusType} />
        break;

      default:
        break;
    }

    return fragment;
  };

  /**
   *  Render the component
   */
  return (
    <>
      <Grid className="header" item={true} xs={12}>
        <Typography variant="body1">{t("menu.header.statusSpec")}</Typography>
      </Grid>

      <Grid className="status-wrapper" container={true}>
        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>

          <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
            {
              STATUS_TYPE_LIST.map(s =>
                s === "blast" && Moment("2024-08-20").diff(Moment(), "days") > 0 ?
                  <Badge color="error" variant="dot" key={s}>
                    <Button key={s} variant={statusType === s ? "contained" : "text"} size="small" onClick={() => statusNav(s)}>
                      {t(`labels.statusCondition.${s}`)}
                    </Button>
                  </Badge>
                  :
                  <Button key={s} variant={statusType === s ? "contained" : "text"} size="small" onClick={() => statusNav(s)}>
                    {t(`labels.statusCondition.${s}`)}
                  </Button>
              )
            }
          </Stack>
        </Grid>
        <Grid item={true} xs={12}>&nbsp;</Grid>
        <Grid item={true} xs={12}>
          {renderFragment()}
        </Grid>
      </Grid >

      <BottomNav admin={false} />
    </>
  );
}